import create from "zustand";
import { DEFAULT_DATEPICKER_END_MOMENT, DEFAULT_DATEPICKER_START_MOMENT } from "../constants";
import { useState } from "react";
import { Dictionary } from "@fullcalendar/core/internal";

const initEvent: Dictionary = {};

export interface ArbeitszeitStateInterface {
  aktFilialToken: string;
  detailDataIstArbeitszeiten: Dictionary;
  detailDataKorrektur: Dictionary;
  endDate: string;
  isModalConfirmationVisible: boolean;
  isModalInformationVisible: boolean;
  isModalIstZeitenVisible: boolean;
  isModalKorrekturVisible: boolean;
  pausenVisible: number;
  selectedData: string;
  setAktFilialToken: (aktFilialToken: string) => void;
  setDetailDataIstArbeitszeiten: (detailDataIstArbeitszeiten: Dictionary) => void;
  setDetailDataKorrektur: (detailDataKorrektur: Dictionary) => void;
  setEndDate: (endDate: string) => void;
  setIsModalConfirmationVisible: (isModalConfirmationVisible: boolean) => void;
  setIsModalInformationVisible: (isModalInformationVisible: boolean) => void;
  setIsModalIstZeitenVisible: (isModalIstZeitenVisible: boolean) => void;
  setIsModalKorrekturVisible: (isModalKorrekturVisible: boolean) => void;
  setPausenVisible: (pausenVisible: number) => void;
  setSelectedData: (selectedData: string) => void;
  setStartDate: (startDate: string) => void;
  setSubmitValues: (startDate: {}) => void;
  startDate: string;
  submitValues: {};
}

export const useArbeitszeitStore = create<ArbeitszeitStateInterface>()((set) => ({
  aktFilialToken: "",
  detailDataIstArbeitszeiten: initEvent,
  detailDataKorrektur: initEvent,
  endDate: DEFAULT_DATEPICKER_END_MOMENT.hour(0).minute(0).second(0).format(),
  isModalConfirmationVisible: false,
  isModalInformationVisible: false,
  isModalIstZeitenVisible: false,
  isModalKorrekturVisible: false,
  pausenVisible: 1,
  selectedData: "",
  setAktFilialToken: (aktFilialToken: string) => set({ aktFilialToken }),
  setDetailDataIstArbeitszeiten: (detailDataIstArbeitszeiten: Dictionary) => set({ detailDataIstArbeitszeiten }),
  setDetailDataKorrektur: (detailDataKorrektur: Dictionary) => set({ detailDataKorrektur }),
  setEndDate: (endDate: string) => set({ endDate }),
  setIsModalConfirmationVisible: (isModalConfirmationVisible: boolean) => set({ isModalConfirmationVisible }),
  setIsModalInformationVisible: (isModalInformationVisible: boolean) => set({ isModalInformationVisible }),
  setIsModalIstZeitenVisible: (isModalIstZeitenVisible: boolean) => set({ isModalIstZeitenVisible }),
  setIsModalKorrekturVisible: (isModalKorrekturVisible: boolean) => set({ isModalKorrekturVisible }),
  setPausenVisible: (pausenVisible: number) => set({ pausenVisible }),
  setSelectedData: (selectedData: string) => set({ selectedData }),
  setStartDate: (startDate: string) => set({ startDate }),
  setSubmitValues: (submitValues: {}) => set({ submitValues }),
  startDate: DEFAULT_DATEPICKER_START_MOMENT.format(),
  submitValues: {},
}));
