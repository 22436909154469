import { Button, Divider, Form, Modal } from "antd";
import { globalMemory } from "../../../globals";
import React from "react";
import { GENERICTYPE_MODAL_SIZE } from "../../../types/genericTypes";

const IWInformationModal = (props: any) => {
  const handleSubmit = () => {
    props.closeAction(false);
  };

  return (
    <Modal
      title={globalMemory.modalTitle}
      width={GENERICTYPE_MODAL_SIZE.GENERICTYPE_MODAL_SIZE_MEDIUM}
      open={props.displayModal}
      footer={false}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
    >
      {globalMemory.informationModal.informationMA} / {globalMemory.informationModal.informationDate}
      <Divider></Divider>
      {globalMemory.informationModal.informationText}
      <Divider></Divider>
      <Form
        name="basic"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 450 }}
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              position: "absolute",
              left: 350,
              top: 30,
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            Ok
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IWInformationModal;
