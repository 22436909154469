import React, { useMemo, useRef } from "react";

import { useParams } from "react-router";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveRadar } from "@nivo/radar";
import style from "../Datev/Datev.module.css";
import {
  Breadcrumb,
  Button,
  Col,
  FloatButton,
  Input,
  Layout,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import IWRangePicker from "../../uiElemente/IWRangePicker/IWRangePicker";
import IWPdfButton from "../../uiElemente/IWPdfButton/IWPdfButton";
import { RowInput } from "jspdf-autotable";
import moment, { Moment } from "moment";
import { useVerbuende } from "../../../hooks/useVerbuende";
import { useFilialen } from "../../../hooks/useFilialen";
import { DEFAULT_DATEPICKER_END_MOMENT, DEFAULT_DATEPICKER_START_MOMENT } from "../../../constants";
import { useStammdaten } from "../../../hooks/useStammdaten";
import { useStatistiken } from "../../../hooks/useStatistiken";
import {
  APIEnumChart,
  APIEnumDatepicker,
  APIEnumLineOrColumn,
  APIFixed,
  APIHorAlignment,
  APISDStatistik,
  APITabHeader,
  APITabSort,
  APIVarType,
} from "../../../types/apiTypes";
import { StatistikSortConfig } from "../../../types/statisticTypes";
import { getWertFromlocalStorage, numberFormatRevers } from "../../../utils/functions";
import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { exportToCSV } from "../Datev/utils";
import {
  getSichtbareSpalten,
  isSpalteSichtbarHeader,
  isSpalteSichtbarZeile,
  isStatistikSelectSpalte,
  isZeileSichtbar,
} from "../../../utils/functionsStatistik";
import IWDatePicker from "../../uiElemente/IWDatePicker/IWDatePicker";
import { useGenericStore } from "../../../store/zustandGenericStore";
import { useStatistikStore } from "../../../store/zustandStatistikStore";

const Statistik = () => {
  const { setSearchText, setSearchedColumn } = useGenericStore();
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedData,
    setSelectedData,
    selectedDataSecond,
    setSelectedDataSecond,
    labelSelect,
    setLabelSelect,
    labelSelectSecond,
    setLabelSelectSecond,
    showSelect,
    setShowSelect,
    showSelectSecond,
    setShowSelectSecond,
    chartDataIndex,
    setChartDataIndex,
    chartDataIndexManual,
    setChartDataIndexManual,
    chartTitle,
    setChartTitle,
    aktStatistik,
    setAktStatistik,
  } = useStatistikStore();

  const { Option } = Select;
  const {
    statistikID,
    navType,
    navToken = "",
  } = useParams<{ statistikID: string; navType: string; navToken: string }>();

  if (aktStatistik !== statistikID) {
    setAktStatistik(statistikID);
    setStartDate(DEFAULT_DATEPICKER_START_MOMENT.format());
    setEndDate(DEFAULT_DATEPICKER_END_MOMENT.format());
    setSelectedData("");
    setSelectedDataSecond("");
  }

  const { stammdaten } = useStammdaten();

  const { Content } = Layout;
  const { Title } = Typography;
  let alias = "";
  let isVerbund = navType === "verbund";
  let isFiliale = navType === "filiale";
  let isAlle = navType === "all";

  const { verbuende } = useVerbuende({ enabled: isVerbund });
  const { filialen } = useFilialen({ enabled: isFiliale });

  // Verbünde und Filialen laden, wenn wir auf einer Verbund- bzw. Filialseite sind
  let filialToken = navToken ? [navToken] : ["ignore"];

  if (isVerbund) {
    const verbund = verbuende.find((v) => v.verbundToken === navToken);
    if (verbund) {
      filialToken = verbund.filialen;
      alias = verbund.alias;
    }
  }

  if (isFiliale) {
    const filiale = filialen.find((f) => f.filialToken === navToken);
    if (filiale) alias = filiale.alias;
  }

  if (isAlle) {
    filialToken = [];
  }

  const handleChangeDateRange = ([startMoment, endMoment]: [Moment, Moment]) => {
    setStartDate(startMoment.format());
    setEndDate(endMoment.format());
  };

  const handleChangeDate = (startMoment: Moment) => {
    //any da ich zu dumm bin StartOf zu importieren bzw da zu doll gekapselt
    let strRange: any = "day";
    if (sdStatistikTypeDatePicker.includes(APIEnumDatepicker.YEAR)) {
      strRange = "year";
    } else if (sdStatistikTypeDatePicker.includes(APIEnumDatepicker.QUARTER)) {
      strRange = "quarter";
    } else if (sdStatistikTypeDatePicker.includes(APIEnumDatepicker.MONTH)) {
      strRange = "month";
    } else {
      strRange = "day";
    }
    setStartDate(startMoment.startOf(strRange).format());
    setEndDate(startMoment.endOf(strRange).format());
  };

  const aktSDStatistik = () => {
    if (Object.keys(stammdaten).length >= 1 && stammdaten.statistiken !== null) {
      for (let apisdStatistik of stammdaten.statistiken) {
        if (apisdStatistik.id.toString() === statistikID) {
          return apisdStatistik;
        }
      }
    }
    return "";
  };

  /**
   * Const für Chart
   */
  const chartType =
    Object.keys(stammdaten).length >= 1 &&
    stammdaten !== null &&
    (aktSDStatistik() as APISDStatistik).chartType !== null &&
    (aktSDStatistik() as APISDStatistik).chartType !== undefined
      ? (aktSDStatistik() as APISDStatistik).chartType
      : APIEnumChart.NONE;

  const chartLineOrColumn =
    Object.keys(stammdaten).length >= 1 &&
    stammdaten !== null &&
    (aktSDStatistik() as APISDStatistik).chartLineOrColumn !== null &&
    (aktSDStatistik() as APISDStatistik).chartLineOrColumn !== undefined
      ? (aktSDStatistik() as APISDStatistik).chartLineOrColumn
      : APIEnumLineOrColumn.LINE;

  const chartDataLabel =
    Object.keys(stammdaten).length >= 1 &&
    stammdaten !== null &&
    (aktSDStatistik() as APISDStatistik).chartDataLabel !== null &&
    (aktSDStatistik() as APISDStatistik).chartDataLabel !== undefined
      ? (aktSDStatistik() as APISDStatistik).chartDataLabel
      : 0;

  if (
    Object.keys(stammdaten).length >= 1 &&
    stammdaten !== null &&
    (aktSDStatistik() as APISDStatistik).chartDataIndex !== null &&
    (aktSDStatistik() as APISDStatistik).chartDataIndex !== undefined
  ) {
    if (chartDataIndex !== (aktSDStatistik() as APISDStatistik).chartDataIndex && !chartDataIndexManual)
      setChartDataIndex((aktSDStatistik() as APISDStatistik).chartDataIndex);
  } else {
    if (chartDataIndex === -1) {
      setChartDataIndex(0);
    }
  }

  const chartIgnoreDataIndex =
    Object.keys(stammdaten).length >= 1 &&
    stammdaten !== null &&
    (aktSDStatistik() as APISDStatistik).chartIgnoreDataIndex !== null &&
    (aktSDStatistik() as APISDStatistik).chartIgnoreDataIndex !== undefined
      ? (aktSDStatistik() as APISDStatistik).chartIgnoreDataIndex
      : [];

  /**
   * Const für Datepicker
   */
  const sdStatistikTypeDatePicker =
    Object.keys(stammdaten).length >= 1 && stammdaten !== null && true
      ? (aktSDStatistik() as APISDStatistik).typeDatepicker
      : "";
  const sdStatistikTitel = stammdaten !== null && true ? (aktSDStatistik() as APISDStatistik).titel : "";

  const sdStatistikDatepicker =
    Object.keys(stammdaten).length >= 1 && stammdaten !== null && true
      ? (aktSDStatistik() as APISDStatistik).showDatepicker
      : false;
  const sdStatistikPDFButton = stammdaten !== null && true ? (aktSDStatistik() as APISDStatistik).showPDFButton : false;

  let sdStatistikSelectBoxLine: number;
  if (Object.keys(stammdaten).length >= 1 && stammdaten !== null) {
    if (showSelect !== (aktSDStatistik() as APISDStatistik).showSelectBoxLine) {
      setShowSelect((aktSDStatistik() as APISDStatistik).showSelectBoxLine);
    }
    sdStatistikSelectBoxLine = (aktSDStatistik() as APISDStatistik).showSelectBoxLine;
  } else {
    if (showSelect !== 0) {
      setShowSelect(0);
    }
    sdStatistikSelectBoxLine = 0;
  }

  let sdStatistikSelectBoxColumn: number;
  if (Object.keys(stammdaten).length >= 1 && stammdaten !== null) {
    if (showSelectSecond !== (aktSDStatistik() as APISDStatistik).showSelectBoxColumn) {
      setShowSelectSecond((aktSDStatistik() as APISDStatistik).showSelectBoxColumn);
    }
    sdStatistikSelectBoxColumn = (aktSDStatistik() as APISDStatistik).showSelectBoxColumn;
  } else {
    if (showSelect !== 0) {
      setShowSelectSecond(0);
    }
    sdStatistikSelectBoxColumn = 0;
  }

  if (
    Object.keys(stammdaten).length >= 1 &&
    stammdaten !== null &&
    (aktSDStatistik() as APISDStatistik).labelSelectBoxLine
  ) {
    if (labelSelect !== (aktSDStatistik() as APISDStatistik).labelSelectBoxLine) {
      setLabelSelect((aktSDStatistik() as APISDStatistik).labelSelectBoxLine);
    }
  } else {
    if (labelSelect !== "Kein Label") {
      setLabelSelect("Kein Label");
    }
  }

  if (
    Object.keys(stammdaten).length >= 1 &&
    stammdaten !== null &&
    (aktSDStatistik() as APISDStatistik).labelSelectBoxColumn
  ) {
    if (labelSelectSecond !== (aktSDStatistik() as APISDStatistik).labelSelectBoxColumn) {
      setLabelSelectSecond((aktSDStatistik() as APISDStatistik).labelSelectBoxColumn);
    }
  } else {
    if (labelSelectSecond !== "Kein Label") {
      setLabelSelectSecond("Kein Label");
    }
  }

  /**
   *  Statistik laden
   */
  const statistikIDQuery = parseInt(statistikID);
  const { isLoading, statistik } = useStatistiken({
    userToken: getWertFromlocalStorage("loginInfo", "userToken"),
    filialToken: filialToken,
    startDate: startDate,
    endDate: endDate,
    statistikIDQuery: statistikIDQuery,
  });

  const statistikRAW = statistik;

  /**
   * Tabellendaten für Anzeige generieren
   */
  const tableData = useMemo(() => {
    const tableRAW = statistikRAW.tabZeilen;
    let arrZeilen: {}[] = [];

    let sichtbareSpalten: number[] = getSichtbareSpalten(statistikRAW);
    if (tableRAW !== undefined) {
      for (const apiStatistikTabZeile of tableRAW) {
        if (isZeileSichtbar(selectedData, apiStatistikTabZeile, sdStatistikSelectBoxLine, sdStatistikSelectBoxColumn)) {
          let zeileTemp: { [index: string]: any } = {};
          for (let i = 0; i < apiStatistikTabZeile.spalten.length; i++) {
            let spalteSichtbar = false;
            for (const x of sichtbareSpalten) {
              if (x === i) {
                spalteSichtbar = true;
              }
            }

            if (
              isSpalteSichtbarZeile(
                selectedData,
                apiStatistikTabZeile,
                sdStatistikSelectBoxLine,
                spalteSichtbar,
                i,
                statistikRAW,
                sdStatistikSelectBoxColumn
              ) ||
              (sdStatistikSelectBoxColumn + 1 === i && aktStatistik.includes("500"))
            ) {
              let zeile: string = apiStatistikTabZeile.spalten[i];
              let identifier = "spalte" + i;

              zeileTemp[identifier] = zeile;
            }
          }
          if (
            selectedDataSecond.includes(apiStatistikTabZeile.spalten[sdStatistikSelectBoxLine]) ||
            selectedDataSecond.length === 0
          ) {
            arrZeilen.push(zeileTemp);
          }
        }
      }
    }

    return arrZeilen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stammdaten, statistikRAW, selectedData, selectedDataSecond]);

  /**
   * Options für Select generieren
   */
  const selectData = useMemo(() => {
    const tableRAW = statistikRAW.tabZeilen;
    const headerRAW = statistikRAW.tabHeader;
    let arrZeilen: {}[] = [];
    let arrNames: String[] = [];
    /**
     *
     */
    // eslint-disable-next-line
    if ((sdStatistikSelectBoxLine = -1)) {
      sdStatistikSelectBoxLine = 0;
    }
    /**
     *
     */
    let sichtbareSpalten: number[] = getSichtbareSpalten(statistikRAW);

    if (!isStatistikSelectSpalte(sdStatistikSelectBoxColumn)) {
      if (tableRAW) {
        for (let tabZeileApi of tableRAW) {
          if (!arrNames.includes(tabZeileApi.spalten[sdStatistikSelectBoxLine])) {
            arrNames.push(tabZeileApi.spalten[sdStatistikSelectBoxLine]);
          }
        }
        arrNames.sort();
        for (let zeileName of arrNames) {
          zeileName = zeileName.replace("/", "");
          arrZeilen.push(
            <Option key={"" + zeileName} value={"" + zeileName}>
              {"" + zeileName}
            </Option>
          );
        }
      }
    } else {
      if (headerRAW) {
        for (let j = 0; j < headerRAW.length; j++) {
          if (sichtbareSpalten.includes(j) && j !== sichtbareSpalten[0]) {
            arrZeilen.push(
              <Option key={"" + headerRAW[j].text} value={"" + headerRAW[j].text}>
                {"" + headerRAW[j].text}
              </Option>
            );
          }
        }
      }
    }
    return arrZeilen;
  }, [stammdaten, statistikRAW]);

  /**
   * bei jedem Select wird der State aktualisiert
   */
  const handleChangeSelect = (value: any) => {
    setSelectedData(value);
  };

  /**
   * Options für den zweiten Select generieren
   */
  const selectDataSecondSelect = useMemo(() => {
    const tableRAW = statistikRAW.tabZeilen;
    let arrZeilenSecond: {}[] = [];
    let arrNames: string[] = [];

    // eslint-disable-next-line
    if ((sdStatistikSelectBoxLine = -1)) {
      sdStatistikSelectBoxLine = 0;
    }
    if (tableRAW) {
      for (let tabZeileApi of tableRAW) {
        let lineValue = tabZeileApi.spalten[sdStatistikSelectBoxLine];
        if (!arrNames.includes(lineValue)) {
          arrZeilenSecond.push(
            <Option key={"" + lineValue} value={"" + lineValue}>
              {"" + lineValue}
            </Option>
          );
          arrNames.push(lineValue);
        }
      }
    }

    return arrZeilenSecond;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stammdaten, statistikRAW]);

  /**
   * bei jedem SelectSecnd wird der State aktualisiert
   */
  const handleChangeSelectSecond = (value: any) => {
    setSelectedDataSecond(value);
  };

  let searchInput = useRef(null);

  /**
   * Die Spalten für die Suche definieren und suchbar machen
   * @param dataIndex
   */
  const getColumnSearchProps = (dataIndex: string) => ({
    // @ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Bitte Suchtext eingeben`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suche
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
            Suche löschen
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: JSX.Element) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: string, record: { [x: string]: { toString: () => string } }) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  /**
   * Die Suche in der Tabelle
   * @param selectedKeys
   * @param confirm
   * @param dataIndex
   */
  const handleSearch = (selectedKeys: string, confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };

  /**
   * Suchfilter löschen
   * @param clearFilters
   */
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  /**
   * Tabellenkopf generieren
   */
  const tableDataHeader = useMemo(() => {
    const headerRAW = statistikRAW.tabHeader;
    let arrHeader: object[] = [];
    if (headerRAW) {
      for (let i = 0; i < headerRAW.length; i++) {
        let spalte: APITabHeader = headerRAW[i];
        let ausrichtung: string = "";

        if (spalte.statisch === APIFixed.APIFIXED_LEFT) ausrichtung = "left";
        else if (spalte.statisch === APIFixed.APIFIXED_RIGHT) ausrichtung = "right";
        if (
          isSpalteSichtbarHeader(spalte, selectedData, i, sdStatistikSelectBoxColumn) ||
          (sdStatistikSelectBoxColumn + 1 === i && aktStatistik.includes("500"))
        ) {
          let actSpaltenObject: { [index: string]: any } = {
            align: "left",
            dataIndex: "",
            title: "",
            width: "15",
            defaultSortOrder: "",
            fixed: ausrichtung,
          };

          if (spalte.suchbar) {
            actSpaltenObject = {
              align: "left",
              dataIndex: "",
              title: "",
              width: "15",
              defaultSortOrder: "",
              fixed: ausrichtung,
              ...getColumnSearchProps("spalte" + i),
            };
          }

          actSpaltenObject.title = spalte.text;
          actSpaltenObject.dataIndex = "spalte" + i;
          actSpaltenObject.width = spalte.breiteProzent + "%"; // spalte.breiteProzent + "%";"75px"

          if (spalte.sortierbar && spalte.typ === APIVarType.APIVARTYPE_NUMBER) {
            actSpaltenObject["sorter"] = (a: { [x: string]: string }, b: { [x: string]: string }) =>
              numberFormatRevers(a["spalte" + i]) - numberFormatRevers(b["spalte" + i]);
          } else if (spalte.sortierbar && spalte.typ === APIVarType.APIVARTYPE_DATE) {
            actSpaltenObject["sorter"] = (
              a: { [x: string]: moment.MomentInput },
              b: { [x: string]: moment.MomentInput }
            ) => moment(a["spalte" + i], "DD.MM.YYYY").unix() - moment(b["spalte" + i], "DD.MM.YYYY").unix();
          } else if (spalte.sortierbar && spalte.typ === APIVarType.APIVARTYPE_STRING) {
            actSpaltenObject["sorter"] = (a: { [x: string]: string }, b: { [x: string]: any }) =>
              a["spalte" + i].localeCompare(b["spalte" + i]);
          }
          if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_RIGHT) {
            actSpaltenObject.align = "right";
          } else if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_CENTER) {
            actSpaltenObject.align = "center";
          }
          if (spalte.stdSortierung === APITabSort.APITABSORT_DESC) {
            actSpaltenObject.defaultSortOrder = "descend";
          } else if (spalte.stdSortierung === APITabSort.APITABSORT_ASC) {
            actSpaltenObject.defaultSortOrder = "ascend";
          }

          arrHeader.push(actSpaltenObject);
        }
      }
    }
    return arrHeader;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stammdaten, statistikRAW, selectedData, selectedDataSecond]);

  /**
   * Welche Spalten werden wie sortiert
   */
  const getSortConfig = useMemo(() => {
    const headerRAW = statistikRAW.tabHeader;
    let searchID: StatistikSortConfig = {
      id: 0,
      order: APITabSort.APITABSORT_NONE,
      type: APIVarType.APIVARTYPE_STRING,
    };
    if (headerRAW !== undefined) {
      for (let i = 0; i < headerRAW.length; i++) {
        let spalte: APITabHeader = headerRAW[i];
        if (spalte.stdSortierung !== APITabSort.APITABSORT_NONE) {
          searchID.id = i;
          searchID.order = spalte.stdSortierung;
          searchID.type = spalte.typ;
        }
      }
    }
    return searchID;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stammdaten, statistikRAW]);

  /**
   * Tabelleninhalt für PDF und CSV generieren
   */
  const tableDataArray = useMemo(() => {
    const tableRAW = statistikRAW.tabZeilen;
    let arrZeilen: string[][] = [];

    let sichtbareSpalten: number[] = getSichtbareSpalten(statistikRAW);

    if (tableRAW !== undefined) {
      for (const apiStatistikTabZeile of tableRAW) {
        // Wenn keine Select Auswahl getroffen zeige alle oder wenn Select ausgewählt sind zeige nur die gewählten
        if (isZeileSichtbar(selectedData, apiStatistikTabZeile, sdStatistikSelectBoxLine, sdStatistikSelectBoxColumn)) {
          let zeileTemp: string[] = [];
          for (let i = 0; i < apiStatistikTabZeile.spalten.length; i++) {
            let spalteSichtbar = false;
            for (const x of sichtbareSpalten) {
              if (x === i) {
                spalteSichtbar = true;
              }
            }
            if (
              isSpalteSichtbarZeile(
                selectedData,
                apiStatistikTabZeile,
                sdStatistikSelectBoxLine,
                spalteSichtbar,
                i,
                statistikRAW,
                sdStatistikSelectBoxColumn
              ) ||
              (sdStatistikSelectBoxColumn + 1 === i && aktStatistik.includes("500"))
            ) {
              let zeile: string = apiStatistikTabZeile.spalten[i];
              zeileTemp.push(zeile);
            }
          }

          if (
            selectedDataSecond.includes(apiStatistikTabZeile.spalten[sdStatistikSelectBoxLine]) ||
            selectedDataSecond.length === 0
          ) {
            arrZeilen.push(zeileTemp);
          }
        }
      }
    }

    if (getSortConfig.order !== APITabSort.APITABSORT_NONE) {
      if (getSortConfig.type === APIVarType.APIVARTYPE_NUMBER) {
        arrZeilen.sort((a, b) => numberFormatRevers(a[getSortConfig.id]) - numberFormatRevers(b[getSortConfig.id]));
      } else if (getSortConfig.type === APIVarType.APIVARTYPE_DATE) {
        arrZeilen.sort(
          (a, b) => moment(a[getSortConfig.id], "DD.MM.YYYY").unix() - moment(b[getSortConfig.id], "DD.MM.YYYY").unix()
        );
      } else {
        arrZeilen.sort((a, b) => a[getSortConfig.id].localeCompare(b[getSortConfig.id]));
      }

      if (getSortConfig.order === APITabSort.APITABSORT_DESC) {
        arrZeilen.reverse();
      }
    }

    return arrZeilen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stammdaten, statistikRAW, selectedData, selectedDataSecond]);

  /**
   * Tabellenkopf für PDF und CSV generieren
   */
  const tableHeaderArray = useMemo(() => {
    const headerRAW = statistikRAW.tabHeader;
    let arrHeader: string[] = [];
    if (headerRAW !== undefined) {
      for (let i = 0; i < headerRAW.length; i++) {
        let spalte: APITabHeader = headerRAW[i];
        if (
          isSpalteSichtbarHeader(spalte, selectedData, i, sdStatistikSelectBoxColumn) ||
          (sdStatistikSelectBoxColumn + 1 === i && aktStatistik.includes("500"))
        ) {
          arrHeader.push(spalte.text);
        }
      }
    }
    return arrHeader;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stammdaten, statistikRAW, selectedData]);

  /**
   * Styleobjekt für Tabellenheader generieren
   */
  const pdfStyleObject = useMemo(() => {
    const headerRAW = statistikRAW.tabHeader;
    let arrHeader: any = {};
    if (headerRAW !== undefined) {
      for (let i = 0; i < headerRAW.length; i++) {
        let spalte: APITabHeader = headerRAW[i];
        if (isSpalteSichtbarHeader(spalte, selectedData, i, sdStatistikSelectBoxColumn)) {
          let alignString = "left";
          if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_CENTER) alignString = "center";
          else if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_RIGHT) alignString = "right";
          arrHeader[i] = { halign: alignString };
        }
      }
    }
    return arrHeader;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stammdaten, statistikRAW]);

  /**
   * Funktion zum setzen des Charttitels
   */
  const checkChartTitle = (titleGot: string) => {
    if (titleGot !== chartTitle) {
      setChartTitle(titleGot);
    }
  };

  /**
   * Erzeugen des Datenobjektes und Keyobjektes für den BarChart
   */
  let chartKeys: any[] = [];
  let barChartData = () => {
    let returnChartData: any = [];
    if (chartType === APIEnumChart.BARCHART) {
      if (chartLineOrColumn === APIEnumLineOrColumn.LINE) {
        for (let i = 0; i < tableDataArray.length; i++) {
          let actRow = tableDataArray[i];
          let actReturnRow: { [index: string]: any } = {};
          if (chartDataIndex === i) {
            let maxRows = actRow.length;
            let arrIndexChecker = [];
            for (let chartIndexIgnore = 0; chartIndexIgnore < chartIgnoreDataIndex.length; chartIndexIgnore++) {
              if (chartIgnoreDataIndex[chartIndexIgnore] < 0) {
                if (selectedData.length < 1 && selectedDataSecond.length < 1) {
                  arrIndexChecker.push(maxRows - chartIgnoreDataIndex[chartIndexIgnore] - 2);
                }
              } else {
                arrIndexChecker.push(chartIgnoreDataIndex[chartIndexIgnore]);
              }
            }

            for (let j = 0; j < actRow.length; j++) {
              if ((actRow[j].match(/[a-zA-Z]+/g) == null || j === chartDataLabel) && !arrIndexChecker.includes(j)) {
                let keyName = "";
                if (j === chartDataLabel) {
                  keyName = "label";
                } else {
                  keyName = tableHeaderArray[j];
                }

                actReturnRow[keyName] = actRow[j].replace(".", "").replace(",", ".").replace("€", "").replace("%", "");
                if (i === chartDataIndex && j !== chartDataLabel) {
                  chartKeys.push(tableHeaderArray[j]);
                }
              }
            }
            checkChartTitle(actRow[chartDataLabel]);

            returnChartData.push(actReturnRow);
          }
        }
      } else {
        for (let i = 0; i < tableHeaderArray.length; i++) {
          if (chartDataIndex === i) {
            let actReturnRow: { [index: string]: any } = {};
            actReturnRow["label"] = tableHeaderArray[chartDataIndex];
            returnChartData.push(actReturnRow);

            let maxRows = tableDataArray.length;
            let arrIndexChecker = [];

            for (let chartIndexIgnore = 0; chartIndexIgnore < chartIgnoreDataIndex.length; chartIndexIgnore++) {
              if (chartIgnoreDataIndex[chartIndexIgnore] < 0) {
                if (selectedData.length < 1 && selectedDataSecond.length < 1) {
                  arrIndexChecker.push(maxRows - chartIgnoreDataIndex[chartIndexIgnore] - 2);
                }
              } else if (chartIgnoreDataIndex[chartIndexIgnore] >= 0) {
                arrIndexChecker.push(chartIgnoreDataIndex[chartIndexIgnore]);
              }
            }

            for (let j = 0; j < tableDataArray.length; j++) {
              let actRow = tableDataArray[j];
              if (actRow[i].match(/[a-zA-Z]+/g) == null && !arrIndexChecker.includes(j)) {
                chartKeys.push(tableDataArray[j][chartDataLabel]);
                let rowName = "" + tableDataArray[j][chartDataLabel];
                actReturnRow[rowName] = actRow[i].replace(".", "").replace(",", ".").replace("€", "").replace("%", "");
              }
            }
            checkChartTitle(tableHeaderArray[chartDataIndex]);

            returnChartData.push(actReturnRow);
          }
        }
      }
    }

    return returnChartData;
  };

  /**
   * Allgemeine Properties für den BarChart
   */
  const commonPropsBarChartIW = {
    height: 320,
    margin: { top: 5, right: 100, bottom: 30, left: 50 },
    data: barChartData(),
    indexBy: "label",
    keys: chartKeys,
    padding: 0.02,
    borderRadius: 5,
    borderWidth: 1,
  };

  // /**
  //  * Erzeugen des Datenobjektes und Keyobjektes für den LineChart
  //  */
  // let lineChartData = () => {
  //   let returnChartData: any = [];
  //   for (let i = 0; i < tableHeaderArray.length; i++) {
  //     let actLineObject = {};
  //     let actColumn = tableHeaderArray[i];
  //     let istNurNumerisch = false;
  //     // @ts-ignore
  //     actLineObject["id"] = actColumn;
  //     // @ts-ignore
  //     actLineObject["color"] = "hsl(290, 70%, 50%)";
  //     let lineSingleDataObject: {}[] = [];
  //     for (let j = 0; j < tableDataArray.length; j++) {
  //       let actRow = tableDataArray[j];
  //       if ((actRow[i].match(/[a-zA-Z]+/g) == null || i == 0) && !chartIgnoreDataIndex.includes(i)) {
  //         let actData = { x: actRow[chartDataLabel], y: actRow[i].replace(",", ".") };
  //         lineSingleDataObject.push(actData);
  //         istNurNumerisch = true;
  //       }
  //     }
  //     // @ts-ignore
  //     actLineObject["data"] = lineSingleDataObject;
  //     if (istNurNumerisch) {
  //       returnChartData.push(actLineObject);
  //     }
  //   }
  //
  //   return returnChartData;
  // };
  //
  // /**
  //  * Allgemeine Properties für den LineChart
  //  */
  // const commonPropsLineChartIW = {
  //   width: 800,
  //   height: 300,
  //   margin: { top: 0, right: 100, bottom: 30, left: 30 },
  //   data: lineChartData(),
  //   padding: 0.2,
  // };

  /**
   * Erzeugen des Datenobjektes und Keyobjektes für den PieChart
   */
  let pieChartData = () => {
    let returnChartData: any = [];
    if (chartType === APIEnumChart.PIECHART) {
      if (chartLineOrColumn === APIEnumLineOrColumn.LINE) {
        for (let i = 0; i < tableDataArray.length; i++) {
          let actRow = tableDataArray[i];
          if (chartDataIndex === i) {
            for (let j = 0; j < actRow.length; j++) {
              if (
                (actRow[j].match(/[a-zA-Z]+/g) === null || j !== chartDataLabel) &&
                !chartIgnoreDataIndex.includes(j)
              ) {
                let actRowData = {
                  id: tableHeaderArray[j],
                  value: actRow[j].replace(",", ".").replace("€", "").replace("%", ""),
                };
                checkChartTitle(actRow[chartDataLabel]);
                returnChartData.push(actRowData);
              }
            }
          }
        }
      } else {
        for (let i = 0; i < tableDataArray.length; i++) {
          let actRow = tableDataArray[i];

          let actRowData = {
            id: actRow[chartDataLabel],
            value: actRow[chartDataIndex].replace(",", ".").replace("€", "").replace("%", ""),
          };
          checkChartTitle(tableHeaderArray[chartDataIndex]);
          returnChartData.push(actRowData);
        }
      }
    }

    return returnChartData;
  };

  /**
   * Allgemeine Properties für den PieChart
   */
  const commonPropsPieChartIW = {
    width: 800,
    height: 350,
    margin: { top: 20, right: 0, bottom: 10, left: 0 },
    data: pieChartData(),
    padding: 0.2,
    innerRadius: 0.1,
    cornerRadius: 5,
    activeOuterRadiusOffset: 5,
    enableArcLinkLabels: false,
    borderWidth: 1,
  };

  const refBottom = React.useRef(null);
  const refTop = React.useRef(null);

  // @ts-ignore
  const executeScrollTop = () => refTop.current.scrollIntoView({ behavior: "smooth", block: "start" });
  // @ts-ignore
  const executeScrollBottom = () => refBottom.current.scrollIntoView({ behavior: "smooth", block: "start" });

  if (navType) {
    return (
      <div style={{ width: "101%", height: "100%", border: 0, overflowY: "auto" }}>
        <div ref={refTop}></div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          {<Breadcrumb.Item>{navType === "all" ? "Alle Filialen" : alias}</Breadcrumb.Item>}
        </Breadcrumb>
        <Content className="global-layout-background global-content">
          <div style={{ width: "100%", height: 50, marginBottom: 50, textAlign: "center" }}>
            <div className={style.headline}>
              <Title level={2}>{sdStatistikTitel}</Title>
            </div>
            {sdStatistikPDFButton ? (
              <div className={style.headerButtons}>
                <IWPdfButton
                  tableDataArray={tableDataArray as RowInput[]}
                  tableHeaderArray={tableHeaderArray as string[]}
                  pdfHeader={
                    `${sdStatistikTitel} vom ${moment(startDate).format("DD.MM.YYYY")} bis ${moment(endDate).format(
                      "DD.MM.YYYY"
                    )} - ` + (navType === "all" ? "Alle Filialen" : alias)
                  }
                  fileName={`${sdStatistikTitel}_${moment(startDate).format("DD_MM_YYYY")}-${moment(endDate).format(
                    "DD_MM_YYYY"
                  )}_${navType === "all" ? "Alle_Filialen" : alias}`}
                  theme={"striped"}
                  headStyle={{
                    halign: "center",
                    fillColor: "CornFlowerBlue",
                    lineWidth: 1,
                    lineColor: "CornFlowerBlue",
                  }}
                  columnStyle={pdfStyleObject}
                  showPage={true}
                  showDate={true}
                />
                <Button
                  style={{ marginLeft: 5 }}
                  type="primary"
                  onClick={() =>
                    exportToCSV(
                      `Statistik_${sdStatistikTitel}_${moment(startDate).format("DD.MM.YYYY")}-${moment(endDate).format(
                        "DD.MM.YYYY"
                      )}_${navType === "all" ? "Alle_Filialen" : alias}.csv`,
                      [tableHeaderArray, ...tableDataArray]
                    )
                  }
                >
                  CSV Export
                </Button>
              </div>
            ) : null}
          </div>

          {chartType !== APIEnumChart.NONE ? (
            <div
              style={{
                width: "95%",
                fontSize: "large",
                marginBottom: 5,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {chartTitle}
            </div>
          ) : null}

          {chartType === APIEnumChart.BARCHART && tableDataArray.length !== 0 ? (
            <div style={{ width: "95%", height: 350, marginBottom: 0, textAlign: "center" }}>
              <ResponsiveBar
                {...commonPropsBarChartIW}
                groupMode={"grouped"}
                colors={{ scheme: "set3" }}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fontSize: 12,
                      },
                    },
                  },
                }}
                legends={[
                  {
                    anchor: "top-right",
                    dataFrom: "keys",
                    direction: "column",
                    itemHeight: 18,
                    itemWidth: 150,
                    toggleSerie: true,
                    translateX: 150,
                    translateY: 0,
                    symbolShape: "circle",

                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          ) : null}

          {chartType === APIEnumChart.RADIALCHART ? (
            <div style={{ width: "100%", height: 300, marginBottom: 100, marginTop: 0, textAlign: "center" }}>
              <ResponsiveRadar
                {...commonPropsBarChartIW}
                colors={{ scheme: "pastel1" }}
                curve="catmullRomClosed"
                borderWidth={1}
                legends={[
                  {
                    anchor: "right",
                    direction: "column",
                    itemHeight: 20,
                    itemWidth: 150,
                    toggleSerie: true,
                    translateX: 0,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          ) : null}

          {/*{showLineChart ? (*/}
          {/*  <div style={{ height: 320, marginBottom: 100, textAlign: "center" }}>*/}
          {/*    <ResponsiveLine*/}
          {/*      {...commonPropsLineChartIW}*/}
          {/*      colors={{ scheme: "pastel1" }}*/}
          {/*      curve="monotoneX"*/}
          {/*      useMesh={true}*/}
          {/*      legends={[*/}
          {/*        {*/}
          {/*          anchor: "right",*/}
          {/*          direction: "column",*/}
          {/*          itemHeight: 20,*/}
          {/*          itemWidth: 150,*/}
          {/*          toggleSerie: true,*/}
          {/*          translateX: 160,*/}
          {/*          symbolShape: "circle",*/}
          {/*          effects: [*/}
          {/*            {*/}
          {/*              on: "hover",*/}
          {/*              style: {*/}
          {/*                itemBackground: "rgba(0, 0, 0, .03)",*/}
          {/*                itemOpacity: 1,*/}
          {/*              },*/}
          {/*            },*/}
          {/*          ],*/}
          {/*        },*/}
          {/*      ]}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*) : null}*/}

          {chartType === APIEnumChart.PIECHART ? (
            <div style={{ width: "100%", height: 300, marginBottom: 100, marginTop: 0, textAlign: "center" }}>
              <ResponsivePie
                {...commonPropsPieChartIW}
                colors={{ scheme: "pastel1" }}
                legends={[
                  {
                    anchor: "right",

                    direction: "column",
                    itemHeight: 20,
                    itemWidth: 150,
                    toggleSerie: true,
                    translateX: 0,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          ) : null}

          <div className={style.contentBlock} id="content_block">
            <Title level={2}>
              <Spin size="large" spinning={isLoading} style={{ alignItems: "center" }} tip="Lade Daten...">
                <Table
                  className={style.datevTable}
                  size="small"
                  columns={tableDataHeader}
                  pagination={{
                    position: ["bottomCenter"],
                    defaultPageSize: 100,
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        if (chartLineOrColumn === APIEnumLineOrColumn.LINE) {
                          setChartDataIndex(rowIndex as number);
                          setChartDataIndexManual(true);
                        }
                      },
                    };
                  }}
                  dataSource={tableData}
                  scroll={{ x: "calc( " + tableDataHeader.length * 120 + "px )" }}
                />
              </Spin>
            </Title>
          </div>
          <div ref={refBottom}></div>
          {isStatistikSelectSpalte(showSelectSecond) ? (
            <Row justify="start">
              {isStatistikSelectSpalte(showSelectSecond) ? (
                <Col lg={6} xl={5} xxl={3} style={{ textAlign: "start" }}>
                  {labelSelect}:&nbsp;
                </Col>
              ) : (
                <Col lg={6} xl={5} xxl={3} style={{ textAlign: "start" }}>
                  {labelSelectSecond}:&nbsp;
                </Col>
              )}
              <Col lg={9} xl={6} xxl={5}>
                <div>
                  <Select
                    mode="multiple"
                    allowClear={true}
                    style={{
                      marginBottom: "5px",
                      width: "450px",
                    }}
                    placeholder="Bitte wählen Sie"
                    onChange={handleChangeSelectSecond}
                  >
                    {selectDataSecondSelect}
                  </Select>
                </div>
              </Col>
            </Row>
          ) : null}

          {showSelect !== -1 ? (
            <Row justify="start">
              {isStatistikSelectSpalte(showSelectSecond) ? (
                <Col lg={6} xl={5} xxl={3} style={{ textAlign: "start" }}>
                  {labelSelectSecond}:&nbsp;
                </Col>
              ) : (
                <Col lg={6} xl={5} xxl={3} style={{ textAlign: "start" }}>
                  {labelSelect}:&nbsp;
                </Col>
              )}
              <Col lg={9} xl={6} xxl={5}>
                <div>
                  <Select
                    mode="multiple"
                    allowClear={true}
                    style={{
                      marginBottom: "5px",
                      width: "450px",
                    }}
                    placeholder="Bitte wählen Sie"
                    onChange={handleChangeSelect}
                  >
                    {selectData}
                  </Select>
                </div>
              </Col>
            </Row>
          ) : null}

          {sdStatistikDatepicker || !sdStatistikTypeDatePicker.includes(APIEnumDatepicker.NONE) ? (
            <Row justify="start">
              {sdStatistikTypeDatePicker.includes("_") ? (
                <IWRangePicker onClick={handleChangeDateRange} range={sdStatistikTypeDatePicker} />
              ) : (
                <IWDatePicker onClick={handleChangeDate} aktToken={filialToken} range={sdStatistikTypeDatePicker} />
              )}
            </Row>
          ) : null}

          <FloatButton
            shape="circle"
            type="primary"
            onClick={executeScrollTop}
            style={{
              right: 24,
              bottom: 94,
            }}
            icon={<UpOutlined />}
          />
          <FloatButton
            shape="circle"
            type="primary"
            onClick={executeScrollBottom}
            style={{
              right: 24,
            }}
            icon={<DownOutlined />}
          />
        </Content>
      </div>
    );
  }

  return (
    <Content className="global-layout-background global-content">
      <div className={style.headline}>
        <Title level={2}>Statistiken</Title>
        <Result
          title="Noch keine Statistik oder  Verbund / Filiale ausgewählt"
          extra={[
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <text style={{ textAlign: "left" }}>
                <div>1. Eine Statistik auswählen</div>
                <div>2. Eine Filiale oder einen Vebund auswählen</div>
              </text>
            </div>,
          ]}
        />
      </div>
    </Content>
  );
};

export default React.memo(Statistik);
