import { APISDBuchhaltungskonto } from "../types/apiTypes";

const functionsBuchhaltung = {
  // Konto ID zu Konto Nummer auflösen
  kontoidToKontonr(kontoid: number, konten: APISDBuchhaltungskonto[]) {
    const kontoidStr = kontoid.toString();

    for (let i = 0; i < konten.length; i++) {
      const obj: APISDBuchhaltungskonto = konten[i];

      let idStr = obj.id.toString();

      if (kontoidStr === idStr) return obj.kontonr;
    }

    return 0;
  },

  //---------------------------------------------------------------------------
};

export default functionsBuchhaltung;
