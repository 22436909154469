import { useQuery } from "react-query";
import { fetchData, getWertFromlocalStorage } from "../utils/functions";
import { APPIDENT } from "../constants";
const pkg = require("../../package.json");

interface QueryParams {
  userToken: string;
  filialToken: string[];
  startDate: string;
  endDate: string;
  bruttoNetto: string;
  buchhaltungZusammenfassKonten: number[];
}

const getQuery = ({
  userToken,
  filialToken,
  startDate,
  endDate,
  bruttoNetto,
  buchhaltungZusammenfassKonten,
}: QueryParams) => ({
  query: `query {
      getBuchungsstapel(
          appIdent: ${APPIDENT},    
          version: "${pkg.version}",    
          userToken: "${userToken}",
          filialToken: ${JSON.stringify(filialToken)},
          datum1: "${startDate}",
          datum2: "${endDate}",
          ausgabe: ${bruttoNetto},
          kontenZusammengefasst: [${buchhaltungZusammenfassKonten}]) {
        header {
          success,
          errorCode,
          errorText
        },
    
        data {
          id,
          datum,
          belegNr,
          datevNr,
          text,
          betrag,
          sollKonto,
          habenKonto,
          kst,
          mwstSatz
        }
      }
    }`,
});

export const useBookings = (queryParams: QueryParams, queryOptions = {}) => {
  if (getWertFromlocalStorage("loginInfo", "userToken") === "") queryOptions = { enabled: false };

  let queryObject = getQuery(queryParams);
  let { isLoading, error, data } = useQuery(
    ["buchungen", queryObject.query],
    () => fetchData(queryObject),
    queryOptions
  );

  const bookings: any[] = data?.data?.data?.getBuchungsstapel?.data ?? [];

  return { isLoading, error, bookings };
};
