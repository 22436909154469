import { APISollAZeitFilialTagAbwesenheit, APISollAZeitFilialTagArbeitszeit } from "./apiTypes";
import { EventImpl } from "@fullcalendar/core/internal";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

export enum GENERICTYPE_PARAMETER_MSM {
  GENERICTYPE_PARAMETER_MSM_MSM = 1,
  GENERICTYPE_PARAMETER_MSM_CONNECT = 0,
}

export enum GENERICTYPE_PARAMETER_LOCALDB {
  GENERICTYPE_PARAMETER_LOCALDB_LOCAL = 0,
  GENERICTYPE_PARAMETER_LOCALDB_SERVER = 1,
}

export enum GENERICTYPE_PARAMETER_ACTIVEMODUL {
  GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT = "connect",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_DIENSTPLAN = "dienstplan",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_ARBEITSZEITNACHWEIS = "arbeitszeitnachweis",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_STATISTIK = "statistik",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_FORECAST = "forecast",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_EINSTELLUNGEN = "einstellungen",
}

export enum GENERICTYPE_MODAL_SIZE {
  GENERICTYPE_MODAL_SIZE_SMALL = 400,
  GENERICTYPE_MODAL_SIZE_MEDIUM = 450,
  GENERICTYPE_MODAL_SIZE_LARGE = 700,
}

export type typeConfirmationModalContent = {
  confirmationDate: string;
  confirmationMA: string;
  confirmationText: ReactJSXElement;
  confirmationSubmitText: string;
  confirmationCancelText: string;
  confirmationSubmitCall: EventImpl;
};

export type typeInformationModalContent = {
  informationDate: string;
  informationMA: string;
  informationText: string;
  informationSubmitText: string;
};

export type typeUndoCache = {
  index: number;
  type: string;
  datum: string;
  maID: string;
  arbeitszeit: APISollAZeitFilialTagArbeitszeit;
  abwesenheit: APISollAZeitFilialTagAbwesenheit;
};

export type tpyeKorrektur = {
  korrektur: string;
  summeUeberstunden: string;
  korrekturDate: string;
};

export type tpyePausen = {
  pauseVon: string;
  pauseBis: string;
};
