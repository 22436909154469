import create from "zustand";
import { DEFAULT_DATEPICKER_END_MOMENT, DEFAULT_DATEPICKER_START_MOMENT } from "../constants";
import {
  localStorageDatevAusgabeBruttoNetto,
  localStorageDatevZusammenfassKonten,
} from "../components/pages/Datev/localStorageVars";

export interface DatevStateInterface {
  displaySettingsModal: boolean;
  setDisplaySettingsModal: (displaySettingsModal: boolean) => void;
  startDate: string;
  setStartDate: (startDate: string) => void;
  endDate: string;
  setEndDate: (endDate: string) => void;
  bruttoNetto: string;
  setBruttoNetto: (bruttoNetto: string) => void;
  draftRowKeysSettings: number[];
  setDraftRowKeysSettings: (draftRowKeysSettings: number[]) => void;
  rowKeysSettings: number[];
  setRowKeysSettings: (rowKeysSettings: number[]) => void;
}

export const useDatevStore = create<DatevStateInterface>()((set) => ({
  bruttoNetto: localStorageDatevAusgabeBruttoNetto(),
  displaySettingsModal: false,
  draftRowKeysSettings: [0],
  endDate: DEFAULT_DATEPICKER_END_MOMENT.format(),
  rowKeysSettings: localStorageDatevZusammenfassKonten(),
  setBruttoNetto: (bruttoNetto: string) => set({ bruttoNetto }),
  setDisplaySettingsModal: (displaySettingsModal: boolean) => set({ displaySettingsModal }),
  setDraftRowKeysSettings: (draftRowKeysSettings: number[]) => set({ draftRowKeysSettings }),
  setEndDate: (endDate: string) => set({ endDate }),
  setRowKeysSettings: (rowKeysSettings: number[]) => set({ rowKeysSettings }),
  setStartDate: (startDate: string) => set({ startDate }),
  startDate: DEFAULT_DATEPICKER_START_MOMENT.format(),
}));
