import React, { useMemo, useRef, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Layout,
  Modal,
  Radio,
  Result,
  Row,
  Space,
  Spin,
  Table,
  Tabs,
  Typography,
} from "antd";
import moment, { Moment } from "moment";
import "moment/locale/de";
import style from "./Datev.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { COLUMNS_SETTINGS } from "./constants";
import { KontoDatevSettingsTabellenausgabe } from "../../../types/datevTypes";
import IWRangePicker from "../../uiElemente/IWRangePicker/IWRangePicker";
import { showInfoMessageAusgabe, showInfoMessageUebersicht } from "./notifications";
import { exportToCSV, getCSVData, getTableData, getTableDataArray } from "./utils";
import {
  setLocalStorageDatevZusammenfassKonten,
  localStorageDatevAusgabeBruttoNetto,
  setLocalStorageDatevAusgabeBruttoNetto,
} from "./localStorageVars";
import { useStammdaten } from "../../../hooks/useStammdaten";
import { useBookings } from "../../../hooks/useBookings";
import { useVerbuende } from "../../../hooks/useVerbuende";
import { useFilialen } from "../../../hooks/useFilialen";
import IWPdfButton from "../../uiElemente/IWPdfButton/IWPdfButton";
import { APIEnumDatepicker, APISDBuchhaltungskonto } from "../../../types/apiTypes";
import { RowInput } from "jspdf-autotable";
import { getWertFromlocalStorage } from "../../../utils/functions";
import { SearchOutlined } from "@ant-design/icons";
import { useGenericStore } from "../../../store/zustandGenericStore";
import { useDatevStore } from "../../../store/zustandDatevStore";

const { Content } = Layout;
const { Title } = Typography;

const { TabPane } = Tabs;

const Datev = () => {
  const { type, token = "" } = useParams<{ type: string; token: string }>();

  // Stammdaten laden, für Buchhaltungskonten-Informationen
  const { stammdaten } = useStammdaten();

  let isVerbund = type === "verbund";
  let isFiliale = type === "filiale";

  // Verbünde und Filialen laden, wenn wir auf einer Verbund- bzw. Filialseite sind
  const { verbuende } = useVerbuende({ enabled: isVerbund });
  const { filialen } = useFilialen({ enabled: isFiliale });

  const { searchText, setSearchText, searchedColumn, setSearchedColumn } = useGenericStore();
  const {
    draftRowKeysSettings,
    setDraftRowKeysSettings,
    rowKeysSettings,
    setRowKeysSettings,
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    displaySettingsModal,
    setDisplaySettingsModal,
    bruttoNetto,
    setBruttoNetto,
  } = useDatevStore();

  let alias = "";

  let filialToken = token ? [token] : [];

  if (isVerbund) {
    const verbund = verbuende.find((v) => v.verbundToken === token);
    if (verbund) {
      filialToken = verbund.filialen;
      alias = verbund.alias;
    }
  }

  if (isFiliale) {
    const filiale = filialen.find((f) => f.filialToken === token);
    if (filiale) alias = filiale.alias;
  }

  // Buchungen laden
  const { isLoading, bookings } = useBookings({
    userToken: getWertFromlocalStorage("loginInfo", "userToken"),
    filialToken: filialToken,
    startDate: startDate,
    endDate: endDate,
    bruttoNetto: bruttoNetto,
    buchhaltungZusammenfassKonten: rowKeysSettings,
  });

  // Tabellendaten generieren
  const tableData = useMemo(() => {
    return stammdaten.buchhaltungskonten && bookings ? getTableData(bookings, stammdaten.buchhaltungskonten) : [];
  }, [bookings, stammdaten]);

  // Tabellendaten generieren
  const tableDataArray = useMemo(() => {
    return stammdaten.buchhaltungskonten && bookings ? getTableDataArray(bookings, stammdaten.buchhaltungskonten) : [];
  }, [bookings, stammdaten]);

  const [tableDataSettings, setTableDataSettings] = useState([] as KontoDatevSettingsTabellenausgabe[]);

  const rowSelectionSettings = {
    selectedRowKeys: draftRowKeysSettings,
    onChange: (selectedRowKeysRKA: React.Key[]) => {
      let selectedRowKeysTempA: number[] = [];

      selectedRowKeysRKA.forEach((selectedRowKey) => {
        selectedRowKeysTempA.push(parseInt(selectedRowKey.toString()));
      });

      setDraftRowKeysSettings(selectedRowKeysTempA);
    },
  };

  const rowClickSettings = (record: any) => {
    let selectedRowKeysTempA: number[] = [];

    if (draftRowKeysSettings) {
      draftRowKeysSettings.forEach((selectedRowKey) => {
        selectedRowKeysTempA.push(selectedRowKey);
      });
    }

    if (selectedRowKeysTempA.indexOf(record.key) >= 0) {
      selectedRowKeysTempA.splice(selectedRowKeysTempA.indexOf(record.key), 1);
    } else {
      selectedRowKeysTempA.push(record.key);
    }

    setDraftRowKeysSettings(selectedRowKeysTempA);
  };

  const generateTableDataSettings = () => {
    let outputData: KontoDatevSettingsTabellenausgabe[] = [];

    let arr = stammdaten.buchhaltungskonten ?? [];

    for (let i = 0; i < arr.length; i++) {
      let data = {} as KontoDatevSettingsTabellenausgabe;

      let obj = arr[i] as APISDBuchhaltungskonto;

      let kontoStatus = "aktiv";
      if (obj.del) kontoStatus = "gelöscht";

      data.key = obj.id;
      data.kontonr = obj.kontonr;
      data.beschreibung = obj.text;
      data.del = kontoStatus;

      outputData.push(data);
    }

    setTableDataSettings(outputData);
  };

  const showModalEinstellungen = () => {
    setDraftRowKeysSettings(rowKeysSettings);

    generateTableDataSettings();

    setDisplaySettingsModal(true);
  };

  const sendDATEVEinstellungen = () => {
    setRowKeysSettings(draftRowKeysSettings);

    setLocalStorageDatevZusammenfassKonten(draftRowKeysSettings);
  };

  const handleOkEinstellungen = () => {
    sendDATEVEinstellungen();
    setDisplaySettingsModal(false);
  };

  const handleCancelEinstellungen = () => {
    setDisplaySettingsModal(false);
  };

  const handleClickZusammenfassenButton = () => {
    let zusammenFassKonten = [];

    if (stammdaten.buchhaltungskonten) {
      for (const i in stammdaten.buchhaltungskonten) {
        const kontoO = stammdaten.buchhaltungskonten[i] as APISDBuchhaltungskonto;

        if (kontoO.kontotyp !== "BELEG" && kontoO.kontotyp !== "BANKEINZAHLUNG") {
          zusammenFassKonten.push(kontoO.id);
        }
      }
    }

    setDraftRowKeysSettings(zusammenFassKonten);
  };

  const handleChangeDate = ([startMoment, endMoment]: [Moment, Moment]) => {
    setStartDate(startMoment.format());
    setEndDate(endMoment.format());
  };

  /**
   *  Suche für Tabellenspalten
   */

  let searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex: string) => ({
    // @ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Bitte Suchtext eingeben`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suche
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
            Suche löschen
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: JSX.Element) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (
      value: string | number | boolean,
      record: { [x: string]: { toString: () => any } } // TODO ANY entfernen CK
    ) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      // @ts-ignore
      setTimeout(() => searchInput.current?.select(), 100);
    },
    /*    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),*/
  });

  const handleSearch = (selectedKeys: string, confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  let TABLE_COLUMNSEARCH = [
    {
      title: "Belegdatum",
      dataIndex: "datum",
      sorter: (a: any, b: any) => moment(a.datum, "DD.MM.YYYY").unix() - moment(b.datum, "DD.MM.YYYY").unix(),
    },
    {
      title: "Beleg-Nr.",
      dataIndex: "belegNr",
      sorter: (a: any, b: any) => a.belegNr - b.belegNr,
    },
    {
      title: "DATEV-Nr.",
      dataIndex: "datevNr",
      sorter: (a: any, b: any) => a.datevNr - b.datevNr,
    },
    {
      title: "Buchungstext",
      dataIndex: "text",
      width: "30%",
      sorter: (a: any, b: any) => a.text.localeCompare(b.text),
      ...getColumnSearchProps("text"),
    },
    {
      title: "Betrag (EUR)",
      dataIndex: "betrag",
      align: "right" as "right",
    },
    {
      title: "Sollkonto",
      dataIndex: "sollKonto",
      sorter: (a: any, b: any) => a.sollKonto - b.sollKonto,
    },
    {
      title: "Habenkonto",
      dataIndex: "habenKonto",
      sorter: (a: any, b: any) => a.habenKonto - b.habenKonto,
    },
    {
      title: "Kst.",
      dataIndex: "kst",
      sorter: (a: any, b: any) => a.kst - b.kst,
    },
    {
      title: "St.Satz",
      dataIndex: "mwstSatz",
    },
  ];

  // @ts-ignore
  const elementIconGear = (
    <FontAwesomeIcon
      /*@ts-ignore*/
      icon="fa-light fa-gears"
      color={"grey"}
      onClick={showModalEinstellungen}
      size={"lg"}
      style={{ marginLeft: 5 }}
    />
  );

  const elementIconInfoKontenuebersicht = (
    <FontAwesomeIcon
      /*@ts-ignore*/
      icon="fa-light fa-info-circle"
      onClick={showInfoMessageUebersicht}
      className={style.infoBtn}
    />
  );

  const elementIconInfoAusgabe = (
    <FontAwesomeIcon
      /*@ts-ignore*/
      icon="fa-light fa-info-circle"
      onClick={showInfoMessageAusgabe}
      className={style.infoBtn}
    />
  );

  if (type) {
    return (
      <>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>{type === "all" ? "Alle Filialen" : alias}</Breadcrumb.Item>
        </Breadcrumb>
        <Content className="global-layout-background global-content">
          <Spin size="large" spinning={isLoading} style={{ alignItems: "center" }} tip="Lade Daten...">
            <div className={style.headline}>
              <Title level={2}>DATEV Export</Title>
            </div>
            <div className={style.headerButtons}>
              <IWPdfButton
                tableDataArray={tableDataArray as RowInput[]}
                tableHeaderArray={[
                  "Belegdatum",
                  "Beleg-Nr.",
                  "DATEV-Nr.",
                  "Buchungstext",
                  "Betrag (EUR)",
                  "Sollkonto",
                  "Habenkonto",
                  "Kst",
                  "St.Satz",
                ]}
                pdfHeader={
                  `Buchungsstapel vom ${moment(startDate).format("DD.MM.YYYY")} bis ${moment(endDate).format(
                    "DD.MM.YYYY"
                  )} - ` + (type === "all" ? "Alle Filialen" : alias)
                }
                fileName={`Buchungsstapel_${moment(startDate).format("DD_MM_YYYY")}-${moment(endDate).format(
                  "DD_MM_YYYY"
                )}_${type === "all" ? "Alle_Filialen" : alias}`}
                theme={"striped"}
                headStyle={{
                  halign: "center",
                  fillColor: "CornFlowerBlue",
                  lineWidth: 1,
                  lineColor: "CornFlowerBlue",
                }}
                columnStyle={{
                  0: { halign: "left" },
                  1: { halign: "left" },
                  2: { halign: "left" },
                  3: { halign: "left" },
                  4: { halign: "right" },
                  5: { halign: "left" },
                  6: { halign: "left" },
                  7: { halign: "left" },
                  8: { halign: "left" },
                }}
                showPage={true}
                showDate={true}
              />
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                onClick={() =>
                  exportToCSV(
                    `EXTF_Buchungsstapel_${moment(startDate).format("DD.MM.YYYY")}-${moment(endDate).format(
                      "DD.MM.YYYY"
                    )}_${type === "all" ? "Alle_Filialen" : alias}.csv`,
                    getCSVData(bookings, stammdaten.buchhaltungskonten ?? [], startDate, endDate)
                  )
                }
              >
                DATEV Export
              </Button>
              {elementIconGear}
            </div>
            <div className={style.contentBlock} id="content_block">
              <Title level={2}>
                <Table
                  className={style.datevTable}
                  size="small"
                  columns={TABLE_COLUMNSEARCH}
                  pagination={{
                    position: ["bottomCenter"],
                    defaultPageSize: 50,
                  }}
                  dataSource={tableData}
                  scroll={{ y: "calc(100vh - 430px)" }}
                />
              </Title>
            </div>
            <Row justify="start">
              <IWRangePicker onClick={handleChangeDate} range={APIEnumDatepicker.DAY} />
            </Row>

            {/*MODAL DATEV SETTINGS*/}
            <Modal
              title="Datev Einstellungen"
              width={1000}
              visible={displaySettingsModal}
              onOk={handleOkEinstellungen}
              onCancel={handleCancelEinstellungen}
              okText={"Speichern"}
            >
              <Tabs defaultActiveKey="1" type="card">
                <TabPane tab="Einstellungen" key="1">
                  <Row>
                    <Col span={16}>
                      <h2>Kontenübersicht {elementIconInfoKontenuebersicht}</h2>
                      <Table
                        size="small"
                        pagination={false}
                        rowSelection={{
                          ...rowSelectionSettings,
                        }}
                        columns={COLUMNS_SETTINGS}
                        dataSource={tableDataSettings as object[]}
                        scroll={{ y: 350 }}
                        onRow={(record) => ({
                          onClick: () => {
                            rowClickSettings(record);
                          },
                        })}
                      />
                    </Col>
                    <Col offset={1} span={7}>
                      <h2>Ausgabe {elementIconInfoAusgabe}</h2>

                      <Radio.Group
                        defaultValue={localStorageDatevAusgabeBruttoNetto()}
                        onChange={(e) => {
                          setBruttoNetto(e.target.value);
                          setLocalStorageDatevAusgabeBruttoNetto(e.target.value);
                        }}
                      >
                        <Radio.Button value="NETTO">Nettobeträge</Radio.Button>
                        <Radio.Button value="BRUTTO">Bruttobeträge</Radio.Button>
                      </Radio.Group>

                      <Col span={20}>
                        <br />
                        <Button
                          className={style.standardBtn}
                          type="primary"
                          onClick={() => handleClickZusammenfassenButton()}
                        >
                          Standardzusammenfassung
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Datev Optionen" key="2">
                  Diese Einstellungen sind noch nicht verfügbar
                </TabPane>
                <TabPane tab="E-Mail Optionen" key="3">
                  Diese Einstellungen sind noch nicht verfügbar
                </TabPane>
              </Tabs>
            </Modal>
          </Spin>
        </Content>
      </>
    );
  }

  return (
    <Content className="global-layout-background global-content">
      <div className={style.headline}>
        <Title level={2}>DATEV Export</Title>
        <Result
          title="Noch kein Verbund / Filiale ausgewählt"
          extra={[
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <text style={{ textAlign: "left" }}>
                <div>1. Bitte zunächst links eine Auswahl treffen (Alle Filialen, Verbund oder einzelne Filiale)</div>
                <div>2. Betrachtungszeitraum anpassen</div>
              </text>
            </div>,
          ]}
        />
      </div>
    </Content>
  );
};

export default Datev;
