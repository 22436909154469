// localStorage Variablen für Datev ein/ausladen

const datevZusammenfassKontenKey = "datevZusammenfassKonten";
const datevAusgabeFormatBruttoNetto = "datevAusgabeFormatBruttoNetto";
//-----------------------------------------------------------------------------------------------------------------------
// Datev Konten zusammenfassen
export const localStorageDatevZusammenfassKonten = () => {
  let arr: number[] = [];
  const ls: string | null = localStorage.getItem(datevZusammenfassKontenKey);

  if (ls) arr = JSON.parse(ls);

  return arr;
};
//-----------------------------------------------------------------------------------------------------------------------
// Datev Konten speichern
export const setLocalStorageDatevZusammenfassKonten = (kontenA: number[]) => {
  if (kontenA) localStorage.setItem(datevZusammenfassKontenKey, JSON.stringify(kontenA));
};
//-----------------------------------------------------------------------------------------------------------------------
// Datev Zusammenfassung BRUTTO | NETTO
export const localStorageDatevAusgabeBruttoNetto = () => {
  let rueck: string = "BRUTTO"; // Default
  const ls: string | null = localStorage.getItem(datevAusgabeFormatBruttoNetto);

  if (ls) rueck = ls;

  return rueck;
};
//-----------------------------------------------------------------------------------------------------------------------
// Datev Zusammenfassung BRUTTO | NETTO speichern
export const setLocalStorageDatevAusgabeBruttoNetto = (bruttonetto: string) => {
  if (bruttonetto === "BRUTTO" || bruttonetto === "NETTO")
    localStorage.setItem(datevAusgabeFormatBruttoNetto, bruttonetto);
};
//-----------------------------------------------------------------------------------------------------------------------
