import React from "react";
import { useState } from "react";
import { Button } from "antd";
import style from "./IWPdfButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsH, faArrowsV } from "@fortawesome/pro-regular-svg-icons";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";

/**
 *
 * These parameters are mandatory!!!
 *
 * @param fileName as String - defines the file name when saved
 * @param tableDataArray as RowInput[] - the raw data
 * @param tableHeaderArray as String[] - the headers, must have headers for all row columns
 * @param pdfHeader as String - Text written as header on every page
 * @param showPage as boolean - is page number shown on every page
 * @param showDate as boolean -  is date and version shown on every page
 * @param theme as String - can be striped, grid or plain
 * @param headStyle as object - object with style information for header styling
 * @param columnStyle as object - information how to style every column used for left or right align
 * @constructor
 */
const IWPdfButton = ({
  fileName,
  tableDataArray,
  tableHeaderArray,
  pdfHeader,
  showPage,
  showDate,
  theme,
  headStyle,
  columnStyle,
}: any) => {
  const [orientation, setOrientation] = useState<"portrait" | "landscape">("portrait");
  const [isLoadingPDF, setIsLoadingPDF] = useState<true | false>(false);

  const toggleOrientation = () => {
    setOrientation(orientation === "portrait" ? "landscape" : "portrait");
  };

  const onClickPDF = () => {
    let p1 = new Promise((resolve) => {
      setTimeout(() => {
        let doc = new jsPDF(orientation, "px", "a4");
        autoTable(doc, {
          head: [tableHeaderArray],
          body: tableDataArray,
          didDrawPage: function (data) {
            doc.setFontSize(10);
            if (pdfHeader !== "") {
              doc.text(pdfHeader, data.settings.margin.left, 22);
            }

            let strFooterL = "Seite " + doc.getNumberOfPages();
            doc.setFontSize(8);
            doc.setTextColor("silver");
            let pageSize = doc.internal.pageSize;
            let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            if (showPage) doc.text(strFooterL, data.settings.margin.left, pageHeight - 20);
            let strFooterR = "v" + process.env.REACT_APP_VERSION + "     " + moment().format("DD.MM.YYYY HH:mm");
            if (showDate) doc.text(strFooterR, pageWidth - 105, pageHeight - 20);
          },
          rowPageBreak: "avoid",
          theme: theme,
          styles: { fontSize: 8 },
          headStyles: headStyle,
          bodyStyles: { lineWidth: 1 },
          columnStyles: columnStyle,
          alternateRowStyles: { fillColor: [240, 240, 240] },
          showFoot: "everyPage",
        });
        doc.save(fileName + ".pdf", { returnPromise: true });
        resolve("done");
      }, 1000);
    });

    p1.then(() => {
      setIsLoadingPDF(false);
    });
  };

  return (
    <div className={style.druckBtn}>
      <Button
        onClick={function () {
          setIsLoadingPDF(true);
          onClickPDF();
        }}
        type="primary"
      >
        {isLoadingPDF
          ? "PDF wird erzeugt..."
          : orientation === "portrait"
          ? "Drucken (Hochformat)"
          : "Drucken (Querformat)"}
      </Button>
      <Button onClick={() => toggleOrientation()} type="primary">
        <FontAwesomeIcon icon={orientation === "portrait" ? faArrowsV : faArrowsH} />
      </Button>
    </div>
  );
};

export default IWPdfButton;
