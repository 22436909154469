import { Form, Input, Button, notification } from "antd";
import { getQueryLogin, useLogin } from "../../../hooks/useLogin";
import style from "./Login.module.css";
import React from "react";
import logo from "../../images/connect.svg";
import { useAppStore } from "../../../store/zustandAppStore";

const Login = (props: any) => {
  const { loggedIn, setLoggedIn, benutzername, setBenutzername, passwort, setPasswort } = useAppStore();

  const { loginInfo } = useLogin(
    {
      benutzername: benutzername,
      passwort: passwort,
    },
    { enabled: benutzername.length !== 0 && passwort.length !== 0 }
  );

  if (loginInfo.benutzername) {
    // eslint-disable-next-line
    const myTimer = setTimeout(() => {
      setLoggedIn(true);
    }, 50);
  }

  const OnFinish = (values: any) => {
    setBenutzername(values.benutzername.trim());
    setPasswort(values.passwort.trim());
  };

  return (
    <div className={style.loginContainer}>
      <div className={style.loginLogo}>
        <img src={logo} className="App-logo" alt="e-cut Connect" />
      </div>
      <div className={style.connectLogo}>
        <div className={style.loginFormContainer}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 6 }} initialValues={{ remember: false }} onFinish={OnFinish}>
            <Form.Item
              label="Benutzername"
              name="benutzername"
              rules={[{ required: true, message: "Bitte geben Sie Ihren e-cut Connect Benutzernamen ein." }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Passwort"
              name="passwort"
              rules={[{ required: true, message: "Bitte geben Sie Ihr e-cut Connect Passwort ein." }]}
            >
              <Input.Password />
            </Form.Item>

            {/*
        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Angemeldet bleiben</Checkbox>
        </Form.Item>
        */}
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
