import React, { useCallback } from "react";
import { convertToLocalDate } from "../../../utils/functionsDatum";
import dayjs from "dayjs";
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Switch, TimePicker } from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import style from "../Datev/Datev.module.css";
import { APIEnumTurnusTyp } from "../../../types/apiTypes";
import { globalMemory } from "../../../globals";
import { GENERICTYPE_MODAL_SIZE } from "../../../types/genericTypes";
import { useSollArbeitszeitStore } from "../../../store/zustandSollArbeitszeitenStore";
import { useGenericStore } from "../../../store/zustandGenericStore";
import { tippy } from "@tippyjs/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IWArbeitszeitenmodal = (props: any) => {
  const {
    displayNewEvent,
    setDisplayDetailEditZeitKontext,
    setDisplayDetailBisEndlosArbeitszeit,
    setDisplayDetailPause,
    displaySettingsModalArbeitszeit,
    displayDetailBisEndlosArbeitszeit,
    displayDetailPause,
    displayDetailEditZeitKontext,
    displayFirstOpenArbeitszeit,
    setDisplayFirstOpenArbeitszeit,
    isFormChanged,
    setIsFormChanged,
  } = useSollArbeitszeitStore();

  const elementIconClock = (
    /* @ts-ignore langer comment wegen ts-ignore____________________________________*/
    <FontAwesomeIcon icon="fa-light fa-clock" style={{ marginLeft: "15px" }} color={"#1188d8"} />
  );

  const { detailData } = useGenericStore();

  let [form] = Form.useForm(); //TODO CK

  if (detailData.detailPause === null) detailData.detailPause = "00:00";

  if (displayNewEvent) {
    //  setDisplayDetailEditZeitKontext(true);
  }

  /*
  const setDisplayFirstOpen = useCallback(() => {
    props.setDisplayFirstOpen(false);
  }, [props.setDisplayFirstOpen]);
*/

  if (displayFirstOpenArbeitszeit) {
    form.resetFields();
    setDisplayFirstOpenArbeitszeit(false);
  }
  /*
  const setDisplayDetailBisEndlosArbeitszeit = useCallback(
    (value) => {
      props.setDisplayDetailBisEndlosArbeitszeit(value);
    },
    [props.setDisplayDetailBisEndlosArbeitszeit]
  );

  const setDisplayDetailPause = useCallback(
    (value) => {
      props.setDisplayDetailPause(value);
    },
    [props.setDisplayDetailPause]
  );

  const setDisplayDetailEditZeitKontext = useCallback(
    (value) => {
      props.setDisplayDetailEditZeitKontext(value);
    },
    [props.setDisplayDetailEditZeitKontext]
  );
*/
  const handleOkArbeitszeiten = useCallback(
    (values) => {
      props.handleOkArbeitszeiten(values, false);
    },
    [props.handleOkArbeitszeiten]
  );

  const handleCancelEinstellungen = useCallback(() => {
    props.handleCancelEinstellungen();
  }, [props.handleCancelEinstellungen]);

  const disabledDateTime = () => {
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  };

  const calculatePause = (value: dayjs.Dayjs | null, type: string) => {
    if (type.includes("pause1von") && value !== null) globalMemory.pause1Von = value;
    if (type.includes("pause2von") && value !== null) globalMemory.pause2Von = value;
    if (type.includes("pause1bis") && value !== null) globalMemory.pause1Bis = value;
    if (type.includes("pause2bis") && value !== null) globalMemory.pause2Bis = value;

    if (
      (globalMemory.pause1Von.isBefore(globalMemory.pause1Bis) ||
        globalMemory.pause1Von.isSame(globalMemory.pause1Bis)) &&
      (globalMemory.pause2Von.isBefore(globalMemory.pause2Bis) || globalMemory.pause2Von.isSame(globalMemory.pause2Bis))
    ) {
      let dayjsCalculate = globalMemory.pause1Bis;
      let hours1 = globalMemory.pause1Von.format("HH");
      let minute1 = globalMemory.pause1Von.format("mm");
      dayjsCalculate = dayjsCalculate.subtract(Number(minute1), "minutes");
      dayjsCalculate = dayjsCalculate.subtract(Number(hours1), "hours");

      let dayjsCalculate2 = globalMemory.pause2Bis;
      let hours2 = globalMemory.pause2Von.format("HH");
      let minute2 = globalMemory.pause2Von.format("mm");
      dayjsCalculate2 = dayjsCalculate2.subtract(Number(minute2), "minutes");
      dayjsCalculate2 = dayjsCalculate2.subtract(Number(hours2), "hours");

      let hours3 = dayjsCalculate2.format("HH");
      let minute3 = dayjsCalculate2.format("mm");
      dayjsCalculate = dayjsCalculate.add(Number(minute3), "minutes");
      dayjsCalculate = dayjsCalculate.add(Number(hours3), "hours");

      form.setFieldValue("pause", dayjsCalculate);
    }
  };

  return (
    <Modal
      width={GENERICTYPE_MODAL_SIZE.GENERICTYPE_MODAL_SIZE_LARGE}
      open={displaySettingsModalArbeitszeit}
      okText={"Speichern"}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      footer={null}
    >
      <h3>
        {!displayDetailBisEndlosArbeitszeit ? (
          <b>
            Arbeitszeit für {convertToLocalDate(dayjs(detailData.aktTagDetail).format(" dddd"))} von
            {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" DD.MM.YYYY"))} -{" "}
            {convertToLocalDate(dayjs(detailData.detailDatumBis).format(" DD.MM.YYYY"))}
          </b>
        ) : (
          <b>
            Arbeitszeit ab {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" dddd"))}, den{" "}
            {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" DD.MM.YYYY"))}
          </b>
        )}
        <br />
        {detailData.detailMA}
      </h3>

      <Divider></Divider>
      <Form
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        style={{ maxWidth: 800 }}
        onFinish={handleOkArbeitszeiten}
        form={form}
        autoComplete="off"
        onFieldsChange={function () {
          setIsFormChanged(true);
        }}
      >
        <Form.Item label="Intervall" style={{ marginBottom: "0px" }}>
          <Form.Item name="turnus" style={{ display: "inline-block" }} initialValue={detailData.detailTurnusTyp}>
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              onMouseEnter={function (info) {
                tippy(info.currentTarget, {
                  content:
                    'Beispiel mit HTML <br/>und Fontawsome <br/><b>Fett</b><br/><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="50px"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>',
                  placement: "top",
                  interactive: true,
                  arrow: true,
                  theme: "ecut",
                  appendTo: document.body,
                  allowHTML: true,
                  duration: [1, 1],
                  offset: [0, 10],
                });
              }}
              dropdownStyle={{ backgroundColor: "#e1f0fc" }}
              options={[
                { value: APIEnumTurnusTyp.JEDEWOCHE, label: "Jede Woche" },
                { value: APIEnumTurnusTyp.ZWEIWOECHENTLICH, label: "Jede zweite Woche" },
              ]}
            />
          </Form.Item>
          {!displayNewEvent ? (
            <Form.Item
              label="Gültigkeit:"
              name="tagOderTurnus"
              style={{ display: "inline-block", margin: "0 38px" }}
              initialValue={displayDetailEditZeitKontext}
            >
              <Switch
                style={{
                  caretColor: "black",
                  color: "black",
                  width: "150px",
                  backgroundColor: "CornFlowerBlue",
                }}
                defaultChecked={displayDetailEditZeitKontext}
                checkedChildren="Ganze Serie"
                unCheckedChildren="Nur dieser Tag"
                onChange={function (checked: boolean) {
                  setDisplayDetailEditZeitKontext(checked);
                }}
              ></Switch>
            </Form.Item>
          ) : null}
        </Form.Item>

        <Form.Item label="Dauer:" style={{ marginBottom: "0px" }}>
          <Form.Item
            name="unbegrenzt"
            style={{ display: "inline-block" }}
            initialValue={displayDetailBisEndlosArbeitszeit}
          >
            <Switch
              style={{
                caretColor: "blue",
                backgroundColor: displayDetailBisEndlosArbeitszeit ? "CornFlowerBlue" : "lightgray",
              }}
              defaultChecked={displayDetailBisEndlosArbeitszeit}
              checkedChildren="Unbegrenzte Dauer"
              unCheckedChildren="Begrenzte Dauer"
              onChange={function (checked: boolean) {
                setDisplayDetailBisEndlosArbeitszeit(checked);
              }}
            ></Switch>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Gültig ab:" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="gueltigAb"
            style={{ display: "inline-block" }}
            initialValue={dayjs(detailData.detailDatumVon)}
          >
            <DatePicker
              picker={"date"}
              value={dayjs(detailData.detailDatumVon)}
              allowClear={false}
              format={"DD.MM.YYYY"}
              locale={locale}
              onMouseEnter={function (info) {
                tippy(info.currentTarget, {
                  content: "Gibt den Intervall von<br/> Blablabla an.",
                  placement: "top",
                  interactive: true,
                  arrow: true,
                  theme: "ecut",
                  appendTo: document.body,
                  allowHTML: true,
                  duration: [1, 1],
                  offset: [0, 10],
                });
              }}
              inputReadOnly
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              className={style.iwMonthPicker}
            />
          </Form.Item>
          {!displayDetailBisEndlosArbeitszeit ? (
            <>
              <Form.Item
                label=" bis:"
                name="gueltigBis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs(detailData.detailDatumBis)}
              >
                <DatePicker
                  picker={"date"}
                  allowClear={false}
                  format={"DD.MM.YYYY"}
                  locale={locale}
                  inputReadOnly
                  onMouseEnter={function (info) {
                    tippy(info.currentTarget, {
                      content: "Gibt den Intervall von<br/> Blablabla an.",
                      placement: "top",
                      interactive: true,
                      arrow: true,
                      theme: "ecut",
                      appendTo: document.body,
                      allowHTML: true,
                      duration: [1, 1],
                      offset: [0, 10],
                    });
                  }}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </>
          ) : null}
        </Form.Item>
        <Form.Item label="Arbeitszeit:" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="ArbeitszeitAb"
            style={{ display: "inline-block" }}
            initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }

                  const opening_time = getFieldValue("ArbeitszeitBis");

                  //check if opening time is before closing time
                  if (opening_time.diff(value) == 0) {
                    return Promise.reject(new Error("Arbeitszeit von darf nicht gleich bis sein!"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TimePicker
              defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
              allowClear={false}
              format={"HH:mm"}
              locale={locale}
              showNow={false}
              minuteStep={5}
              onMouseEnter={function (info) {
                tippy(info.currentTarget, {
                  content: "Gibt den Intervall von<br/> Blablabla an.",
                  placement: "top",
                  interactive: true,
                  arrow: true,
                  theme: "ecut",
                  appendTo: document.body,
                  allowHTML: true,
                  duration: [1, 1],
                  offset: [0, 10],
                });
              }}
              disabledTime={disabledDateTime}
              changeOnBlur={true}
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            />
          </Form.Item>
          <Form.Item
            label=" bis"
            name="ArbeitszeitBis"
            style={{ display: "inline-block", margin: "0 8px" }}
            initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }

                  const opening_time = getFieldValue("ArbeitszeitAb");

                  //check if opening time is before closing time
                  if (opening_time.diff(value) == 0) {
                    return Promise.reject(new Error("Arbeitszeit von darf nicht gleich bis sein!"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TimePicker
              defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
              allowClear={false}
              format={"HH:mm"}
              locale={locale}
              showNow={false}
              minuteStep={5}
              onMouseEnter={function (info) {
                tippy(info.currentTarget, {
                  content: "Gibt den Intervall von<br/> Blablabla an.",
                  placement: "top",
                  interactive: true,
                  arrow: true,
                  theme: "ecut",
                  appendTo: document.body,
                  allowHTML: true,
                  duration: [1, 1],
                  offset: [0, 10],
                });
              }}
              disabledTime={disabledDateTime}
              changeOnBlur={true}
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              className={style.iwMonthPicker}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Pausentyp" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="PauseVariabel"
            style={{ display: "inline-block" }}
            initialValue={displayDetailPause}
          >
            <Switch
              style={{
                caretColor: "black",
                color: "black",
                width: "150px",

                backgroundColor: "CornFlowerBlue",
              }}
              defaultChecked={displayDetailPause}
              checkedChildren="Fixe Pause"
              unCheckedChildren="Variable Pause"
              onChange={function (checked: boolean) {
                setDisplayDetailPause(checked);
              }}
            ></Switch>
          </Form.Item>
          {/*<Form.Item label="Pause Fix" style={{ display: "inline-block", margin: "0 8px" }}></Form.Item>*/}
        </Form.Item>
        <Form.Item
          label="Pause:"
          name="pause"
          style={{ marginBottom: "15px" }}
          initialValue={dayjs("2000-01-01 " + detailData.detailPause)}
        >
          <TimePicker
            defaultValue={dayjs("2000-01-01 " + detailData.detailPause)}
            allowClear={false}
            format={"HH:mm"}
            disabled={displayDetailPause}
            locale={locale}
            showNow={false}
            onMouseEnter={function (info) {
              tippy(info.currentTarget, {
                content: "Gibt den Intervall von<br/> Blablabla an.",
                placement: "top",
                interactive: true,
                arrow: true,
                theme: "ecut",
                appendTo: document.body,
                allowHTML: true,
                duration: [1, 1],
                offset: [0, 10],
              });
            }}
            minuteStep={5}
            changeOnBlur={true}
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            className={style.iwMonthPicker}
          />
        </Form.Item>
        {displayDetailPause ? (
          <>
            <Form.Item label="Pause 1:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause1Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause1Von)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause1Von)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  onMouseEnter={function (info) {
                    tippy(info.currentTarget, {
                      content: "Gibt den Intervall von<br/> Blablabla an.",
                      placement: "top",
                      interactive: true,
                      arrow: true,
                      theme: "ecut",
                      appendTo: document.body,
                      allowHTML: true,
                      duration: [1, 1],
                      offset: [0, 10],
                    });
                  }}
                  showNow={false}
                  onChange={function (value) {
                    calculatePause(value, "pause1von");
                  }}
                  disabledTime={disabledDateTime}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause1Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause1Bis)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause1Bis)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  onMouseEnter={function (info) {
                    tippy(info.currentTarget, {
                      content: "Gibt den Intervall von<br/> Blablabla an.",
                      placement: "top",
                      interactive: true,
                      arrow: true,
                      theme: "ecut",
                      appendTo: document.body,
                      allowHTML: true,
                      duration: [1, 1],
                      offset: [0, 10],
                    });
                  }}
                  disabledTime={disabledDateTime}
                  onChange={function (value) {
                    calculatePause(value, "pause1bis");
                  }}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label="Pause 2:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause2Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause2Von)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause2Von)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  onMouseEnter={function (info) {
                    tippy(info.currentTarget, {
                      content: "Gibt den Intervall von<br/> Blablabla an.",
                      placement: "top",
                      interactive: true,
                      arrow: true,
                      theme: "ecut",
                      appendTo: document.body,
                      allowHTML: true,
                      duration: [1, 1],
                      offset: [0, 10],
                    });
                  }}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  onChange={function (value) {
                    calculatePause(value, "pause2von");
                  }}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause2Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause2Bis)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause2Bis)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  onMouseEnter={function (info) {
                    tippy(info.currentTarget, {
                      content: "Gibt den Intervall von<br/> Blablabla an.",
                      placement: "top",
                      interactive: true,
                      arrow: true,
                      theme: "ecut",
                      appendTo: document.body,
                      allowHTML: true,
                      duration: [1, 1],
                      offset: [0, 10],
                    });
                  }}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  onChange={function (value) {
                    calculatePause(value, "pause2bis");
                  }}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </Form.Item>{" "}
          </>
        ) : null}

        <Form.Item
          label="Bemerkung:"
          name="bemerkung"
          style={{ marginBottom: "15px" }}
          initialValue={detailData.detailBemerkung}
        >
          <Input
            defaultValue={"" + detailData.detailBemerkung}
            style={{ backgroundColor: "#e1f0fc" }}
            onMouseEnter={function (info) {
              tippy(info.currentTarget, {
                content: "Das ist ein Test",
                placement: "top",
                interactive: true,
                arrow: true,
                theme: "ecut",
                appendTo: document.body,
                allowHTML: true,
                duration: [1, 1],
                offset: [0, 10],
              });
            }}
          ></Input>
        </Form.Item>
        <Divider></Divider>

        <Form.Item wrapperCol={{ offset: 0, span: 30 }} style={{ marginBottom: "0px" }}>
          {!displayNewEvent ? (
            <>
              <Button
                type="primary"
                onClick={props.handleOkDelete}
                style={{
                  backgroundColor: "orangered",
                  color: "white",
                  marginRight: "370px",
                }}
              >
                Löschen
              </Button>
              <Button
                type="primary"
                onClick={handleCancelEinstellungen}
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "#4096ff",

                  marginRight: "5px",
                }}
              >
                Abbrechen
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              onClick={handleCancelEinstellungen}
              style={{
                backgroundColor: "#f5f5f5",
                color: "#4096ff",
                marginLeft: "453px",
                marginRight: "5px",
              }}
            >
              Abbrechen
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            disabled={!isFormChanged}
            style={{
              backgroundColor: isFormChanged ? "#4096ff" : "grey",
              color: "#f5f5f5",
            }}
          >
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(IWArbeitszeitenmodal);
