import { Button, notification } from "antd";
import React from "react";

export const showInfoMessageUebersicht = () => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.destroy(key)}>
      Schließen
    </Button>
  );
  notification.open({
    type: "info",
    duration: 10,
    message: "Kontenzusammenfassung",
    description:
      "Wählen Sie hier aus, welche Konten zusammengefasst werden sollen, damit nicht jede Einzelbuchung erscheint. Somit wird die Ausgabe übersichtlicher und platzsparend dargestellt. Die angehakten Konten werden zusammengefasst.",
    btn,
    key,

    onClick: () => {
      //console.log("Notification Clicked!");
    },
  });
};

export const showInfoMessageAusgabe = () => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.destroy(key)}>
      Schließen
    </Button>
  );
  notification.open({
    type: "info",
    duration: 10,
    message: "Ausgabe Einstellungen",
    description:
      "Legen Sie hier fest, ob die Werte als Brutto- oder Nettobeträge dargestellt werden sollen. Bei Nettobeträgen werden die Umsatzsteuer sowie die Vorsteuer separat ausgewiesen. " +
      "Bei der Standardzusammenfassung werden alle Buchungskonten bis auf das Konten des Bartransfers zusammengefasst.",
    btn,
    key,

    onClick: () => {
      // console.log("Notification Clicked!");
    },
  });
};
