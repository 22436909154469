import { useQuery } from "react-query";
import { fetchData, getWertFromlocalStorage } from "../utils/functions";
import { APIVerbund } from "../types/apiTypes";
import { APPIDENT } from "../constants";
const pkg = require("../../package.json");

interface QueryParams {
  userToken: string;
}

const getQuery = ({ userToken }: QueryParams) => ({
  query: `query {
        getFilialen(
          appIdent: ${APPIDENT},    
          version: "${pkg.version}",    
          userToken: "${userToken}") {
          header {
            success,
            errorCode,
            errorText
          },
          data {
            verbuende {
              alias
              verbundToken
              filialen
            }
          }
        }
      }
      `,
});

export const useVerbuende = (queryOptions = {}) => {
  if (getWertFromlocalStorage("loginInfo", "userToken") === "") queryOptions = { enabled: false };

  const queryParams: QueryParams = {
    userToken: getWertFromlocalStorage("loginInfo", "userToken"),
  };

  let queryObject = getQuery(queryParams);

  let { isLoading, error, data } = useQuery(
    ["verbuende", queryObject.query],
    () => fetchData(queryObject),
    queryOptions
  );

  const verbuende: APIVerbund[] = data?.data?.data?.getFilialen?.data?.verbuende ?? [];

  return { isLoading, error, verbuende };
};
