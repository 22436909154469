// Export von Array Daten als CSV Datei
import { CSV_HEADLINES } from "./constants";
import functionsDatum from "../../../utils/functionsDatum";
import functionsBuchhaltung from "../../../utils/functionsBuchhaltung";
import { numberFormat } from "../../../utils/functions";
import moment from "moment";
import { APIBuchungszeile, APISDBuchhaltungskonto } from "../../../types/apiTypes";
import { DatevBuchungsstapelTabellenausgabe } from "../../../types/datevTypes";

// Anführungszeichen für CSV Export ersetzen
const ersetzeAnfuehrungszeichenCSV = (str: string) => {
  return str.replace(/"/g, '""');
};

export const exportToCSV = (dateiname: string, rows: string[][]) => {
  let processRow = function (row: string[]) {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let result = !row[j] || row[j] === null ? "" : row[j].toString();
      if (j > 0) finalVal += ";";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  let blob = new Blob(["\uFEFF" + csvFile], { type: "text/csv;ISO-8859-1;" });
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // IE 10+
    // @ts-ignore
    navigator.msSaveBlob(blob, dateiname);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", dateiname);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const getTableData = (bookings: APIBuchungszeile[], konten: APISDBuchhaltungskonto[]) => {
  return bookings.map((obj) => {
    const result = {} as DatevBuchungsstapelTabellenausgabe;
    result.key = obj.id;
    result.datum = functionsDatum.datumUTCToPlain(obj.datum);
    result.belegNr = obj.belegNr;
    result.datevNr = obj.datevNr;
    result.text = obj.text;
    result.betrag = numberFormat(obj.betrag);
    result.sollKonto = functionsBuchhaltung.kontoidToKontonr(obj.sollKonto, konten);
    result.habenKonto = functionsBuchhaltung.kontoidToKontonr(obj.habenKonto, konten);
    result.kst = obj.kst;
    result.mwstSatz = `${obj.mwstSatz} %`;
    return result;
  });
};

export const getTableDataArray = (bookings: APIBuchungszeile[], konten: APISDBuchhaltungskonto[]) => {
  return bookings.map((obj) => {
    const result = {} as DatevBuchungsstapelTabellenausgabe;
    result.datum = functionsDatum.datumUTCToPlain(obj.datum);
    result.belegNr = obj.belegNr;
    result.datevNr = obj.datevNr;
    result.text = obj.text;
    result.betrag = numberFormat(obj.betrag);
    result.sollKonto = functionsBuchhaltung.kontoidToKontonr(obj.sollKonto, konten);
    result.habenKonto = functionsBuchhaltung.kontoidToKontonr(obj.habenKonto, konten);
    result.kst = obj.kst;
    result.mwstSatz = `${obj.mwstSatz} %`;
    return Object.values(result);
  });
};

export const getCSVData = (
  bookings: APIBuchungszeile[],
  konten: APISDBuchhaltungskonto[],
  startDate: string,
  endDate: string
) => {
  let outputDataCSV: string[][] = [];

  // Überschrift Zeile 1
  let dataCSV: string[] = [];

  /*
  # 1
  field 'DATEV-Format-KZ', :string, :limit => 4
  # vom Datev angegeben
  # EXTF = für Datei-Formate, die von externen Programmen erstellt wurden
  */
  dataCSV.push('"EXTF"');

  /*
  # 2
  field 'Versionsnummer', :integer, :limit => 3
  # entspricht der zugrundeliegenden Versionsnummer des Schnittstellen-Entwicklungsleitfadens
  */
  dataCSV.push("510");

  /*
  # 3
  field 'Datenkategorie', :integer, :limit => 2
  # vom Datev angegeben
  */
  dataCSV.push("21");

  /*
  # 4
  field 'Formatname', :string
  # vom Datev angegeben
  */
  dataCSV.push('"Buchungsstapel"');

  /*
  # 5
  field 'Formatversion', :integer, :limit => 3
  # vom Datev angegeben
  */
  dataCSV.push("7");

  /*
  # 6
  field 'Erzeugt am', :date, :format => '%Y%m%d%H%M%S%L'
  */
  dataCSV.push(moment().format("YYYYMMDDHHmmssSSS"));

  /*
  # 7
  field 'Importiert', :date
  # Darf nicht gefüllt werden, durch Import gesetzt.
  */
  dataCSV.push("");

  /*
  # 8
  field 'Herkunft', :string, :limit => 2
  # Herkunfts-Kennzeichen
  # Beim Import wird das Herkunfts-Kennzeichen durch „SV“ (= Stapelverarbeitung) ersetzt.
  */
  dataCSV.push('"RE"');

  /*
  # 9
  field 'Exportiert von', :string, :limit => 25
  # Benutzername
  */
  dataCSV.push('"e-cut-Kassensystem"');

  /*
  # 10
  field 'Importiert von', :string, :limit => 10
  # Darf nicht gefüllt werden, durch Import gesetzt.
  */
  dataCSV.push('""');

  /*
  # 11
  field 'Berater', :integer, :limit => 7, :minimum => 1001
  # Beraternummer
  */
  dataCSV.push(""); // Beraternr TODO

  /*
  # 12
  field 'Mandant', :integer, :limit => 5
  # Mandantennummer
  */
  dataCSV.push(""); // Mandantennr TODO

  /*
  # 13
  field 'WJ-Beginn', :date, :format => '%Y%m%d'
  # Wirtschaftsjahresbeginn
  */
  dataCSV.push(moment(startDate).startOf("year").format("YYYYMMDD"));

  /*
  # 14
  field 'Sachkontenlänge', :integer, :limit => 1
  # Kleinste Sachkontenlänge = 4, Grösste Sachkontenlänge = 8
  */
  dataCSV.push("4"); // TODO muss dies einstellbar sein?

  /*
  # 15
  field 'Datum vom', :date, :format => '%Y%m%d'
  */
  dataCSV.push(moment(startDate).format("YYYYMMDD"));

  /*
  # 16
  field 'Datum bis', :date, :format => '%Y%m%d'
  */
  dataCSV.push(moment(endDate).format("YYYYMMDD"));

  /*
  # 17
  field 'Bezeichnung', :string, :limit => 30
  # Bezeichnung des Buchungsstapels
  */
  dataCSV.push('"e-cut ' + moment(startDate).format("DD.MM.YYYY") + "-" + moment(endDate).format("DD.MM.YYYY") + '"');

  /*
  # 18
  field 'Diktatkürzel', :string, :limit => 2
  # Diktatkürzel Beispiel MM = Max Mustermann
  */
  dataCSV.push('""');

  /*
  # 19
  field 'Buchungstyp', :integer, :limit => 1
  # 1 = Finanzbuchhaltung, 2 = Jahresabschluss
  */
  dataCSV.push("1");

  /*
  # 20
  field 'Rechnungslegungszweck' , :integer, :limit => 2
  # 0 oder leer = Rechnungslegungszweckunabhängig
  */
  dataCSV.push("");

  /*
  # 21
  field 'Festschreibung', :boolean
  # leer = nicht definiert; wird ab Jahreswechselversion 2016/2017 automatisch festgeschrieben
  # 0 = keine Festschreibung
  # 1 = Festschreibung
  */
  dataCSV.push("0"); // IntToStr((int)Festschreibekennzeichen)); TODO

  /*
  # 22
  field 'WKZ', :string, :limit => 3
  # Währungskennzeichen
  */
  dataCSV.push('"EUR"');

  /*
  # 23
  field 'reserviert', :string
  */
  dataCSV.push('""');

  /*
  # 24
  field 'Derivatskennzeichen', :string
  */
  dataCSV.push('""');

  /*
  # 25
  field 'reserviert 2', :string
  */
  dataCSV.push('""');

  /*
  # 26
  field 'reserviert 3', :string
  */
  dataCSV.push('""');

  /*
  # 27
  field 'SKR', :string
  */
  dataCSV.push('""');

  /*
  # 28
  field 'Branchenlösung-Id', :integer
  */
  dataCSV.push("");

  /*
  # 29
  field 'reserviert 4', :integer
  */
  dataCSV.push("");

  /*
  # 30
  field 'reserviert 5', :string
  */
  dataCSV.push('""');

  /*
  # 31
  field 'Anwendungsinformation', :string, :limit => 16
  # Verarbeitungskennzeichen der abgebenden Anwendung => Bsp. '9/2016'
  */
  dataCSV.push('"9.9.9.9"'); // ecutVersion); // TODO

  outputDataCSV.push(dataCSV);

  // Überschrift Zeile 2
  outputDataCSV.push(CSV_HEADLINES);

  for (let key in bookings) {
    let obj = bookings[key];

    // Daten für CSV Export
    let dataCSV: string[] = [];

    let betrag: number = parseFloat(obj.betrag);
    let soh: string = "S"; // S || H
    if (betrag < 0.0) {
      betrag *= -1;
      soh = "H";
    }

    dataCSV.push(numberFormat(betrag)); // Umsatz (ohne Soll/Haben-Kz)
    dataCSV.push('"' + soh + '"'); // Soll/Haben-Kennzeichen
    dataCSV.push('""'); // WKZ Umsatz
    dataCSV.push(""); // Kurs
    dataCSV.push(""); // Basisumsatz
    dataCSV.push('""'); // WKZ Basisumsatz
    dataCSV.push(functionsBuchhaltung.kontoidToKontonr(obj.sollKonto, konten).toString()); // Konto
    dataCSV.push(functionsBuchhaltung.kontoidToKontonr(obj.habenKonto, konten).toString()); // Gegenkonto (ohne BU-Schlüssel)
    dataCSV.push('""'); // BU-Schlüssel
    dataCSV.push(moment(obj.datum).format("DDMM")); // Belegdatum
    dataCSV.push('"' + obj.datevNr + '"'); // Belegfeld 1
    dataCSV.push('""'); // Belegfeld 2
    dataCSV.push(""); // Skonto
    dataCSV.push('"' + ersetzeAnfuehrungszeichenCSV(obj.text) + '"'); // Buchungstext
    dataCSV.push(""); // Postensperre
    dataCSV.push('""'); // Diverse Adressnummer
    dataCSV.push(""); // Geschäftspartnerbank
    dataCSV.push(""); // Sachverhalt
    dataCSV.push(""); // Zinssperre
    dataCSV.push('""'); // Beleglink
    dataCSV.push('""'); // Beleginfo – Art 1
    dataCSV.push('""'); // Beleginfo – Inhalt 1
    dataCSV.push('""'); // Beleginfo – Art 2
    dataCSV.push('""'); // Beleginfo – Inhalt 2
    dataCSV.push('""'); // Beleginfo – Art 3
    dataCSV.push('""'); // Beleginfo – Inhalt 3
    dataCSV.push('""'); // Beleginfo – Art 4
    dataCSV.push('""'); // Beleginfo – Inhalt 4
    dataCSV.push('""'); // Beleginfo – Art 5
    dataCSV.push('""'); // Beleginfo – Inhalt 5
    dataCSV.push('""'); // Beleginfo – Art 6
    dataCSV.push('""'); // Beleginfo – Inhalt 6
    dataCSV.push('""'); // Beleginfo – Art 7
    dataCSV.push('""'); // Beleginfo – Inhalt 7
    dataCSV.push('""'); // Beleginfo – Art 8
    dataCSV.push('""'); // Beleginfo – Inhalt 8
    dataCSV.push('"' + obj.kst + '"'); // KOST1 – Kostenstelle
    dataCSV.push('""'); // KOST2 – Kostenstelle
    dataCSV.push(""); // Kost Menge
    dataCSV.push('""'); // EU-Land u. USt-IdNr.
    dataCSV.push(""); // EU-Steuersatz
    dataCSV.push('""'); // Abw. Versteuerungsart
    dataCSV.push(""); // Sachverhalt L+L
    dataCSV.push(""); // Funktionsergänzung L+L
    dataCSV.push(""); // BU 49 Hauptfunktionstyp
    dataCSV.push(""); // BU 49 Hauptfunktionsnummer
    dataCSV.push(""); // BU 49 Funktionsergänzung
    dataCSV.push('""'); // Zusatzinformation – Inhalt 1
    dataCSV.push('""'); // Zusatzinformation – Art 1
    dataCSV.push('""'); // Zusatzinformation – Inhalt 2
    dataCSV.push('""'); // Zusatzinformation – Art 2
    dataCSV.push('""'); // Zusatzinformation – Inhalt 3
    dataCSV.push('""'); // Zusatzinformation – Art 3
    dataCSV.push('""'); // Zusatzinformation – Inhalt 4
    dataCSV.push('""'); // Zusatzinformation – Art 4
    dataCSV.push('""'); // Zusatzinformation – Inhalt 5
    dataCSV.push('""'); // Zusatzinformation – Art 5
    dataCSV.push('""'); // Zusatzinformation – Inhalt 6
    dataCSV.push('""'); // Zusatzinformation – Art 6
    dataCSV.push('""'); // Zusatzinformation – Inhalt 7
    dataCSV.push('""'); // Zusatzinformation – Art 7
    dataCSV.push('""'); // Zusatzinformation – Inhalt 8
    dataCSV.push('""'); // Zusatzinformation – Art 8
    dataCSV.push('""'); // Zusatzinformation – Inhalt 9
    dataCSV.push('""'); // Zusatzinformation – Art 9
    dataCSV.push('""'); // Zusatzinformation – Inhalt 10
    dataCSV.push('""'); // Zusatzinformation – Art 10
    dataCSV.push('""'); // Zusatzinformation – Inhalt 11
    dataCSV.push('""'); // Zusatzinformation – Art 11
    dataCSV.push('""'); // Zusatzinformation – Inhalt 12
    dataCSV.push('""'); // Zusatzinformation – Art 12
    dataCSV.push('""'); // Zusatzinformation – Inhalt 13
    dataCSV.push('""'); // Zusatzinformation – Art 13
    dataCSV.push('""'); // Zusatzinformation – Inhalt 14
    dataCSV.push('""'); // Zusatzinformation – Art 14
    dataCSV.push('""'); // Zusatzinformation – Inhalt 15
    dataCSV.push('""'); // Zusatzinformation – Art 15
    dataCSV.push('""'); // Zusatzinformation – Inhalt 16
    dataCSV.push('""'); // Zusatzinformation – Art 16
    dataCSV.push('""'); // Zusatzinformation – Inhalt 17
    dataCSV.push('""'); // Zusatzinformation – Art 17
    dataCSV.push('""'); // Zusatzinformation – Inhalt 18
    dataCSV.push('""'); // Zusatzinformation – Art 18
    dataCSV.push('""'); // Zusatzinformation – Inhalt 19
    dataCSV.push('""'); // Zusatzinformation – Art 19
    dataCSV.push('""'); // Zusatzinformation – Inhalt 20
    dataCSV.push('""'); // Zusatzinformation – Art 20
    dataCSV.push(""); // Stück
    dataCSV.push(""); // Gewicht
    dataCSV.push(""); // Zahlweise
    dataCSV.push('""'); // Forderungsart
    dataCSV.push(""); // Veranlagungsjahr
    dataCSV.push(""); // Zugeordnete Fälligkeit
    dataCSV.push(""); // Skontotyp
    dataCSV.push('""'); // Auftragsnummer
    dataCSV.push('""'); // Buchungstyp
    dataCSV.push(""); // USt-Schlüssel (Anzahlungen)
    dataCSV.push('""'); // EU-Mitgliedstaat (Anzahlungen)
    dataCSV.push(""); // Sachverhalt L+L (Anzahlungen)
    dataCSV.push(""); // EU-Steuersatz (Anzahlungen)
    dataCSV.push(""); // Erlöskonto (Anzahlungen)
    dataCSV.push('""'); // Herkunft-Kz
    dataCSV.push('""'); // Leerfeld
    dataCSV.push(""); // KOST-Datum
    dataCSV.push('""'); // SEPA-Mandatsreferenz
    dataCSV.push(""); // Skontosperre
    dataCSV.push('""'); // Gesellschaftername
    dataCSV.push(""); // Beteiligtennummer
    dataCSV.push('""'); // Identifikationsnummer
    dataCSV.push('""'); // Zeichnernummer
    dataCSV.push(""); // Postensperre bis
    dataCSV.push('""'); // Bezeichnung SoBil-Sachverhalt
    dataCSV.push(""); // Kennzeichen SoBil-Buchung
    dataCSV.push(""); // Festschreibung
    dataCSV.push(""); // Leistungsdatum
    dataCSV.push(""); // Datum Zuord. Steuerperiode
    outputDataCSV.push(dataCSV);
  }

  return outputDataCSV;
};
