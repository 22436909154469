import { Dictionary } from "@fullcalendar/core/internal";
import { typeKopiereZeitraum } from "./types/sollArbeitszeitenTypes";
import dayjs from "dayjs";
import {
  tpyeKorrektur,
  typeConfirmationModalContent,
  typeInformationModalContent,
  typeUndoCache,
} from "./types/genericTypes";
import { APISDDatumUmstellungNeuesAZeitModellJeFiliale, APISDOeffnungszeitenJeFiliale } from "./types/apiTypes";

export let globalMemory = {
  tagKopiert: "",
  monatKopiert: "",
  wocheKopiert: "",
  tagKopiertQuellMA: "",
  monatKopiertQuellMA: "",
  wocheKopiertQuellMA: "",
  kopieTyp: "",
  loadingDienst: false,
  kopieTag: {} as typeKopiereZeitraum,
  kopieWoche: {} as typeKopiereZeitraum,
  kopieMonat: {} as typeKopiereZeitraum,
  detailData: {} as Dictionary,
  undoCache: [] as typeUndoCache[],
  pause1Von: dayjs().minute(0).hour(0),
  pause1Bis: dayjs().minute(0).hour(0),
  pause2Von: dayjs().minute(0).hour(0),
  pause2Bis: dayjs().minute(0).hour(0),
  urlaubsanspruch: 0,
  token: [],
  urlaubsjahr: 0,
  maid: 0,
  ma: "",
  modalTitle: "Titel nicht gesetzt",
  korrektur: {} as tpyeKorrektur,
  confirmationModal: {} as typeConfirmationModalContent,
  informationModal: {} as typeInformationModalContent,
  modalReturnValue: "",
  modalAktDate: dayjs(),
  modalFilialtoken: [""],
  oeffnungszeitenStammdaten: [] as APISDOeffnungszeitenJeFiliale[],
  umstellungJeFilialeStammdaten: [] as APISDDatumUmstellungNeuesAZeitModellJeFiliale[],
  aktUmstellungsdatum: "2000-01-01",
};
