import { useQuery } from "react-query";
import { fetchData, getWertFromlocalStorage } from "../utils/functions";
import { APIFiliale } from "../types/apiTypes";
import { APPIDENT } from "../constants";
const pkg = require("../../package.json");

interface QueryParams {
  userToken: string;
}

const getQuery = ({ userToken }: QueryParams) => ({
  query: `query {
        getFilialen(
          appIdent: ${APPIDENT},    
          version: "${pkg.version}",    
          userToken: "${userToken}") {
          header {
            success,
            errorCode,
            errorText
          },
          data {
            filialen {
              name,
              alias,
              filialToken
            }
          }
        }
      }`,
});

const getQueryPlaner = ({ userToken }: QueryParams) => ({
  query: `query {
        getFilialen(
          appIdent: ${APPIDENT},    
          version: "${pkg.version}",    
          userToken: "${userToken}") {
          header {
            success,
            errorCode,
            errorText
          },
          data {
            filialen {
              name,
              alias,
              connectToken
            }
          }
        }
      }`,
});

export const useFilialen = (queryOptions = {}) => {
  if (getWertFromlocalStorage("loginInfo", "userToken") === "") queryOptions = { enabled: false };

  const queryParams: QueryParams = {
    userToken: getWertFromlocalStorage("loginInfo", "userToken"),
  };

  let queryObject = getQuery(queryParams);

  let { isLoading, error, data } = useQuery(
    ["filialen", queryObject.query],
    () => fetchData(queryObject),
    queryOptions
  );

  const filialen: APIFiliale[] = data?.data?.data?.getFilialen?.data?.filialen ?? [];

  return { isLoading, error, filialen };
};

export const useFilialenPlaner = (queryOptions = {}) => {
  if (getWertFromlocalStorage("loginInfo", "userToken") === "") queryOptions = { enabled: false };

  const queryParams: QueryParams = {
    userToken: getWertFromlocalStorage("loginInfo", "userToken"),
  };

  let queryObject = getQueryPlaner(queryParams);

  let { isLoading, error, data } = useQuery(
    ["filialen", queryObject.query],
    () => fetchData(queryObject),
    queryOptions
  );

  const filialenPlaner: APIFiliale[] = data?.data?.data?.getFilialen?.data?.filialen ?? [];

  return { isLoading, error, filialenPlaner };
};
