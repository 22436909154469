import React, { useCallback } from "react";
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Switch, TimePicker } from "antd";
import { upperCaseStringFirstLetter } from "../../../utils/functions";
import { convertToLocalDate } from "../../../utils/functionsDatum";
import dayjs from "dayjs";
import { APIEnumTurnusTyp, APIEnumZeitTyp } from "../../../types/apiTypes";
import locale from "antd/es/date-picker/locale/de_DE";
import style from "../Datev/Datev.module.css";
import { useSollArbeitszeitStore } from "../../../store/zustandSollArbeitszeitenStore";
import { useGenericStore } from "../../../store/zustandGenericStore";
import { tippy } from "@tippyjs/react";

const IWAbwesenheitsmodal = (props: any) => {
  let [form] = Form.useForm(); //TODO CK

  const {
    setisUrlaubOderKrank,
    displayNewEvent,
    setDisplayDetailEditZeitKontext,
    isUrlaubOderKrank,
    setDisplayDetailBisEndlosAbwesenheit,
    displaySettingsModalAbwesenheit,
    displayDetailBisEndlosAbwesenheit,
    displayAbwesenheitSelectDisabled,
    displayAbwesenheitSelectSchule,
    displayDetailEditZeitKontext,
    displayFirstOpenAbwesenheit,
    setDisplayFirstOpenAbwesenheit,
    isFormChanged,
    setIsFormChanged,
  } = useSollArbeitszeitStore();

  const { detailData } = useGenericStore();

  const isDatumBisVisible = () => {
    if (displayDetailBisEndlosAbwesenheit) {
      return isUrlaubOderKrank;
    } else {
      return true;
    }
  };

  if (displayFirstOpenAbwesenheit) {
    form.resetFields();
    if (
      (detailData.detailAbwesenheitsTyp === APIEnumZeitTyp.KRANK ||
        detailData.detailAbwesenheitsTyp === APIEnumZeitTyp.URLAUB) &&
      isUrlaubOderKrank
    ) {
      setisUrlaubOderKrank(true);
    }
    if (
      (detailData.detailAbwesenheitsTyp !== APIEnumZeitTyp.KRANK ||
        detailData.detailAbwesenheitsTyp !== APIEnumZeitTyp.URLAUB) &&
      !isUrlaubOderKrank
    ) {
      setisUrlaubOderKrank(false);
    }

    setDisplayFirstOpenAbwesenheit(false);
  }

  const handleKontext = (value: boolean) => {
    setDisplayDetailEditZeitKontext(value);
  };

  const handleOkAbwesenheit = useCallback(
    (values) => {
      props.handleOkAbwesenheit(values, false);
    },
    [props.handleOkAbwesenheit]
  );

  return (
    <Modal
      width={700}
      open={displaySettingsModalAbwesenheit}
      okText={"Speichern"}
      maskClosable={false}
      footer={false}
      closable={false}
      destroyOnClose={true}
    >
      <h3>
        {!displayDetailBisEndlosAbwesenheit ? (
          <b>
            Abwesenheit für {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" dddd"))} von{" "}
            {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" DD.MM.YYYY"))} -{" "}
            {convertToLocalDate(dayjs(detailData.detailDatumBis).format(" DD.MM.YYYY"))}
          </b>
        ) : (
          <b>
            Abwesenheit ab {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" dddd"))} , den{" "}
            {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" DD.MM.YYYY"))}
          </b>
        )}
        <br />
        {detailData.detailMA}
      </h3>
      <Divider></Divider>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        style={{ maxWidth: 800 }}
        onFinish={handleOkAbwesenheit}
        autoComplete="off"
        onFieldsChange={function () {
          setIsFormChanged(true);
        }}
      >
        <Form.Item
          label="Typ :"
          name="detailAbwesenheitsTyp"
          style={{ marginBottom: "15px" }}
          initialValue={detailData.detailAbwesenheitsTyp}
        >
          <Select
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            onMouseEnter={function (info) {
              tippy(info.currentTarget, {
                content: "detailAbwesenheitsTyp",
                placement: "top",
                interactive: true,
                arrow: true,
                theme: "ecut",
                appendTo: document.body,
                allowHTML: true,
                duration: [1, 1],
                offset: [0, 10],
              });
            }}
            options={[
              {
                value: APIEnumZeitTyp.URLAUB,
                label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.URLAUB),
              },
              { value: APIEnumZeitTyp.KRANK, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.KRANK) },
              /**{ value: APIEnumZeitTyp.FEIERTAG, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.FEIERTAG) },**/
              { value: APIEnumZeitTyp.SCHULE, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.SCHULE) },
              /**{ value: APIEnumZeitTyp.RUHETAG, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.RUHETAG) }, **/
              /**{
                     value: APIEnumZeitTyp.WOCHENENDE,
                     label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.WOCHENENDE),
                     },**/
            ]}
            disabled={displayAbwesenheitSelectDisabled}
            onChange={function (value) {
              props.handleChangeAbwesenheitTyp(value);
              if (value === APIEnumZeitTyp.KRANK || value === APIEnumZeitTyp.URLAUB) setisUrlaubOderKrank(true);
              else setisUrlaubOderKrank(false);
            }}
          />
        </Form.Item>

        {!isUrlaubOderKrank ? (
          <Form.Item label="Dauer" style={{ marginBottom: "0px" }}>
            <Form.Item
              name="unbegrenzt"
              style={{ display: "inline-block" }}
              initialValue={displayDetailBisEndlosAbwesenheit}
            >
              <Switch
                style={{
                  caretColor: "blue",
                  backgroundColor: displayDetailBisEndlosAbwesenheit ? "CornFlowerBlue" : "lightgray",
                }}
                defaultChecked={displayDetailBisEndlosAbwesenheit}
                checkedChildren="Unbegrenzte Dauer"
                unCheckedChildren="Begrenzte Dauer"
                onChange={function (checked: boolean) {
                  setDisplayDetailBisEndlosAbwesenheit(checked);
                }}
              ></Switch>
            </Form.Item>
          </Form.Item>
        ) : null}

        <Form.Item label="Gültig ab:" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="gueltigAb"
            style={{ display: "inline-block" }}
            initialValue={dayjs(detailData.detailDatumVon)}
          >
            <DatePicker
              picker={"date"}
              value={dayjs(detailData.detailDatumVon)}
              allowClear={false}
              format={"DD.MM.YYYY"}
              locale={locale}
              inputReadOnly
              onMouseEnter={function (info) {
                tippy(info.currentTarget, {
                  content: "gueltigAb",
                  placement: "top",
                  interactive: true,
                  arrow: true,
                  theme: "ecut",
                  appendTo: document.body,
                  allowHTML: true,
                  duration: [1, 1],
                  offset: [0, 10],
                });
              }}
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              className={style.iwMonthPicker}
            />
          </Form.Item>
          {isDatumBisVisible() ? (
            <>
              <Form.Item
                label=" bis:"
                name="gueltigBis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs(detailData.detailDatumBis)}
              >
                <DatePicker
                  picker={"date"}
                  allowClear={false}
                  format={"DD.MM.YYYY"}
                  locale={locale}
                  inputReadOnly
                  onMouseEnter={function (info) {
                    tippy(info.currentTarget, {
                      content: "gueltigBis",
                      placement: "top",
                      interactive: true,
                      arrow: true,
                      theme: "ecut",
                      appendTo: document.body,
                      allowHTML: true,
                      duration: [1, 1],
                      offset: [0, 10],
                    });
                  }}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </>
          ) : null}
        </Form.Item>

        {displayAbwesenheitSelectSchule ? (
          <Form.Item label="Uhrzeit von :" style={{ marginBottom: "0px" }}>
            <Form.Item
              label=""
              name="ArbeitszeitAb"
              style={{ display: "inline-block" }}
              initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
            >
              <TimePicker
                defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
                allowClear={false}
                format={"HH:mm"}
                locale={locale}
                showNow={false}
                minuteStep={5}
                onMouseEnter={function (info) {
                  tippy(info.currentTarget, {
                    content: "ArbeitszeitAb",
                    placement: "top",
                    interactive: true,
                    arrow: true,
                    theme: "ecut",
                    appendTo: document.body,
                    allowHTML: true,
                    duration: [1, 1],
                    offset: [0, 10],
                  });
                }}
                changeOnBlur={true}
                style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              />
            </Form.Item>
            <Form.Item
              label=" bis"
              name="ArbeitszeitBis"
              style={{ display: "inline-block", margin: "0 8px" }}
              initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
            >
              <TimePicker
                defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
                allowClear={false}
                format={"HH:mm"}
                locale={locale}
                showNow={false}
                minuteStep={5}
                onMouseEnter={function (info) {
                  tippy(info.currentTarget, {
                    content: "ArbeitszeitBis",
                    placement: "top",
                    interactive: true,
                    arrow: true,
                    theme: "ecut",
                    appendTo: document.body,
                    allowHTML: true,
                    duration: [1, 1],
                    offset: [0, 10],
                  });
                }}
                changeOnBlur={true}
                style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                className={style.iwMonthPicker}
              />
            </Form.Item>
          </Form.Item>
        ) : null}
        {
          <Form.Item
            label="Turnus:"
            name="turnus"
            style={{ marginBottom: "15px" }}
            initialValue={detailData.detailTurnusTyp}
          >
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              onMouseEnter={function (info) {
                tippy(info.currentTarget, {
                  content: "turnus",
                  placement: "top",
                  interactive: true,
                  arrow: true,
                  theme: "ecut",
                  appendTo: document.body,
                  allowHTML: true,
                  duration: [1, 1],
                  offset: [0, 10],
                });
              }}
              options={[
                { value: APIEnumTurnusTyp.TAEGLICH, label: "Täglich" },
                { value: APIEnumTurnusTyp.JEDEWOCHE, label: "Jede Woche" },
                { value: APIEnumTurnusTyp.ZWEIWOECHENTLICH, label: "Jede zweite Woche" },
                { value: APIEnumTurnusTyp.MONATLICH, label: "Jeden Monat" },
                { value: APIEnumTurnusTyp.JAEHRLICH, label: "Jedes Jahr" },
              ]}
            />
          </Form.Item>
        }
        {/*
        {props.displayAbwesenheitSelect.includes(APIEnumZeitTyp.KRANK) ||
        props.displayAbwesenheitSelect.includes(APIEnumZeitTyp.URLAUB) ? (
          <Form.Item
            label="Turnus:"
            name="turnus"
            style={{ marginBottom: "15px" }}
            initialValue={APIEnumTurnusTyp.TAEGLICH}
          >
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              disabled={false}
              options={[{ value: APIEnumTurnusTyp.TAEGLICH, label: "Täglich" }]}
            />
          </Form.Item>
        ) : null}

        {props.displayAbwesenheitSelect.includes(APIEnumZeitTyp.FEIERTAG) ? (
          <Form.Item
            label="Turnus:"
            name="turnus"
            style={{ marginBottom: "15px" }}
            initialValue={APIEnumTurnusTyp.JAEHRLICH}
          >
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              disabled={false}
              options={[{ value: APIEnumTurnusTyp.JAEHRLICH, label: "Jedes Jahr" }]}
            />
          </Form.Item>
        ) : null}

        {props.displayAbwesenheitSelect.includes(APIEnumZeitTyp.WOCHENENDE) ? (
          <Form.Item
            label="Turnus:"
            name="turnus"
            style={{ marginBottom: "15px" }}
            initialValue={APIEnumTurnusTyp.JEDEWOCHE}
          >
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              disabled={false}
              options={[{ value: APIEnumTurnusTyp.JEDEWOCHE, label: "Jedes Woche" }]}
            />
          </Form.Item>
        ) : null}*/}

        <Form.Item
          label="Bemerkung:"
          name="bemerkung"
          style={{ marginBottom: "25px" }}
          initialValue={detailData.detailBemerkung}
        >
          <Input
            defaultValue={"" + detailData.detailBemerkung}
            onMouseEnter={function (info) {
              tippy(info.currentTarget, {
                content: "bemerkung",
                placement: "top",
                interactive: true,
                arrow: true,
                theme: "ecut",
                appendTo: document.body,
                allowHTML: true,
                duration: [1, 1],
                offset: [0, 10],
              });
            }}
            style={{ backgroundColor: "#e1f0fc" }}
          ></Input>
        </Form.Item>
        <Divider></Divider>
        {false ? (
          <Form.Item label="Gültigkeit:" style={{ marginBottom: "0px" }}>
            <Form.Item
              label=""
              name="tagOderTurnus"
              style={{ display: "inline-block" }}
              initialValue={displayDetailEditZeitKontext}
            >
              <Switch
                style={{
                  caretColor: "black",
                  color: "black",
                  width: "150px",
                  backgroundColor: "CornFlowerBlue",
                }}
                defaultChecked={displayDetailEditZeitKontext}
                checkedChildren="Ganze Serie"
                unCheckedChildren="Nur dieser Tag"
                onChange={function (checked: boolean) {
                  handleKontext(checked);
                }}
              ></Switch>
            </Form.Item>
          </Form.Item>
        ) : null}
        <Form.Item wrapperCol={{ offset: 0, span: 30 }} style={{ marginBottom: "0px" }}>
          {!displayNewEvent ? (
            <>
              <Button
                type="primary"
                onClick={props.handleOkDelete}
                style={{
                  backgroundColor: "orangered",
                  color: "white",
                  marginRight: "370px",
                }}
              >
                Löschen
              </Button>
              <Button
                type="primary"
                onClick={props.handleCancelEinstellungen}
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "#4096ff",

                  marginRight: "5px",
                }}
              >
                Abbrechen
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              onClick={props.handleCancelEinstellungen}
              style={{
                backgroundColor: "#f5f5f5",
                color: "#4096ff",
                marginLeft: "453px",
                marginRight: "5px",
              }}
            >
              Abbrechen
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: isFormChanged ? "#4096ff" : "grey",
              color: "#f5f5f5",
            }}
          >
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IWAbwesenheitsmodal;
